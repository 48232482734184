import React, { useEffect, useState } from 'react';
import { Tag, Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, DatePicker, Radio } from 'antd';
import idCardTemplateOne from "../../../../../assets/images/ID_1.png";
import idCardTemplateTwo from "../../../../../assets/images/ID_2.png";
import idCardTemplateThree from "../../../../../assets/images/ID_3.png";
import idCardTemplateFour from "../../../../../assets/images/ID_4.png";
import idCardTemplateFive from "../../../../../assets/images/ID_5.png";
import idCardTemplateSix from "../../../../../assets/images/ID_6.png"; 
import idCardTemplateSeven from "../../../../../assets/images/ID_7.png"; 
import idCardTemplateEight from "../../../../../assets/images/ID_8.png"; 
import idCardTemplatNIu from "../../../../../assets/images/ID_9.png"; 
import idCardTemplatTen from "../../../../../assets/images/ID_10.png";
import idCardTemplatEleven from "../../../../../assets/images/ID_11.png";  
import idCardTemplatTwelve from "../../../../../assets/images/ID_12.png";
import idCardTemplatThirteen from "../../../../../assets/images/ID_13.png";  
import idCardTemplatFourteen from "../../../../../assets/images/ID_14.png";  
import idCardTemplatFifteen from "../../../../../assets/images/ID_15.png"; 
import idCardTemplatSixteen from "../../../../../assets/images/ID_16.png";  
import idCardTemplatSeventeen from "../../../../../assets/images/ID_17.png";  
import idCardTemplatNineteen from "../../../../../assets/images/ID_19.png"; 
import idCardTemplateTwenty from "../../../../../assets/images/ID_20.png"; 
import idCardTemplateTwentyOne from "../../../../../assets/images/ID_21.png"; 
import idCardTemplateTwentyTwo from "../../../../../assets/images/ID_22.png";
import idCardTemplateTwentyThree from "../../../../../assets/images/ID_23.jpg";
import idCardTemplateTwentyFour from "../../../../../assets/images/ID_24.png";
import idCardTemplateTwentyFive from "../../../../../assets/images/ID_25.png";
import idCardTemplateTwentySix from "../../../../../assets/images/ID_26.png";
import idCardTemplateTwentySeven from "../../../../../assets/images/ID_27.png";

import idCardTemplateTwentyEight from "../../../../../assets/images/ID_28.png";

import idCardTemplateTwentyNine from "../../../../../assets/images/ID_29.png";

import idCardTemplateThirty from "../../../../../assets/images/ID_30.png";

import idCardTemplateThirtyTwo from "../../../../../assets/images/ID_32.png";

import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';


export default function IdCardTemplate() {
    const fetchIdCardTemplate = useStoreActions((state) => state.student.fetchIdCardTemplate);
    const idCardTemplateList = useStoreState((state) => state.student.idCardTemplateList);
    const idCardSaveTemplate = useStoreActions((state) => state.student.idCardSaveTemplate);
    const [templateDefaultValue, setTemplateDefaultValue] = useState<any>();
    useEffect( () => {
            fetchIdCardTemplate();
    }, []);

    useEffect(() => {
        setTemplateDefaultValue(idCardTemplateList?.templateId);
    }, [idCardTemplateList])

    const templateSaveMethod = (val) => {
        setTemplateDefaultValue(val);
        var postData:any = {
            templateId: val,
            barcodeValue: "Barcode value not found",
        }
        idCardSaveTemplate(postData);
    }
   
    return(
        <>

            {templateDefaultValue !== '' ? 
                <Card title="Student ID Card Template Configuration">
                    <Row>
                        <Col span={24}>
                            <Radio.Group size="large" style={{ width: "100%" }} className='cardTemplateWrapper' onChange={(e) =>  templateSaveMethod(e.target.value)}> 
                                <Row>
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value={101} className={templateDefaultValue == 101 ? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplateOne} className='img-responsive' alt="" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 1</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col>
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="102" className={templateDefaultValue == 102 ? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplateTwo} className='img-responsive' alt="" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 2</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col>
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="103" className={templateDefaultValue == 103 ? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplateThree} className='img-responsive' alt="" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 3</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col>
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="104" className={templateDefaultValue == 104 ? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplateFour} className='img-responsive' alt="" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 4</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col>
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="105" className={templateDefaultValue == 105 ? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplateFive} className='img-responsive' alt="" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 5</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col>
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="106" className={templateDefaultValue == 106 ? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplateSix} className='img-responsive' alt="" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 6</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col>                                   
                                     <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="107" className={templateDefaultValue == 107 ? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplateSeven} className='img-responsive' alt="" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 7</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col>                                     
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="108" className={templateDefaultValue == 108 ? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplateEight} className='img-responsive' alt="" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 8</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col>
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="109" className={templateDefaultValue == 109 ? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplatNIu} className='img-responsive' alt="" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 9</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col>

                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="110" className={templateDefaultValue == 110 ? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplatTen} className='img-responsive' alt="" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 10</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col>

                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="111" className={templateDefaultValue == 111 ? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplatEleven} className='img-responsive' alt="" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 11</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col>

                                     <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="112" className={templateDefaultValue == 112 ? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplatTwelve} className='img-responsive' alt="Template 112" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 12</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col>

                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="113" className={templateDefaultValue == 113 ? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplatThirteen} className='img-responsive' alt="Template 113" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 13</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col>


                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="114" className={templateDefaultValue == 114 ? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplatFourteen} className='img-responsive' alt="Template 114" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 14</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col>

                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="115" className={templateDefaultValue == 115 ? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplatFifteen} className='img-responsive' alt="Template 115" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 15</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col>

                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="116" className={templateDefaultValue == 116 ? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplatSixteen} className='img-responsive' alt="Template 116" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 16</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col>

                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="117" className={templateDefaultValue == 117 ? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplatSeventeen} className='img-responsive' alt="Template 117" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 17</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col>

                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="119" className={templateDefaultValue == 119 ? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplatNineteen} className='img-responsive' alt="Template 119" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 19</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col>

                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="120" className={templateDefaultValue == 120 ? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplateTwenty} className='img-responsive' alt="Template 120" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 20</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col>

                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="121" className={templateDefaultValue == 121 ? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplateTwentyOne} className='img-responsive' alt="Template 121" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 21</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col>

                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="122" className={templateDefaultValue == 122 ? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplateTwentyTwo} className='img-responsive' alt="Template 122" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 22</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col>


                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="123" className={templateDefaultValue == 123 ? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplateTwentyThree} className='img-responsive' alt="Template 123" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 23</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col>


                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="124" className={templateDefaultValue == 124 ? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplateTwentyFour} className='img-responsive' alt="Template 124" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 24</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col>

                                    
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="125" className={templateDefaultValue == 125 ? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplateTwentyFive} className='img-responsive' alt="Template 125" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 25</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col>

                                     <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="126" className={templateDefaultValue == 126 ? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplateTwentySix} className='img-responsive' alt="Template 126" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 26</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col> 

                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="127" className={templateDefaultValue == 127? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplateTwentySeven} className='img-responsive' alt="Template 127" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 27</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col> 

                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="128" className={templateDefaultValue == 128? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplateTwentyEight} className='img-responsive' alt="Template 128" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 28</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col> 

                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="129" className={templateDefaultValue == 129? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplateTwentyNine} className='img-responsive' alt="Template 129" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 29</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col> 


                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="130" className={templateDefaultValue == 130? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplateThirty} className='img-responsive' alt="Template 130" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 30</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col> 

                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="131" className={templateDefaultValue == 131? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplateTwentySix} className='img-responsive' alt="Template 131" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 31</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col> 

                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="132" className={templateDefaultValue == 132? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplateThirtyTwo} className='img-responsive' alt="Template 132" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 32</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col> 
                                </Row>
                            </Radio.Group>
                        </Col>
                    </Row>
                </Card>
            : ''}
        </>
    )
}