import React, { useEffect, useState } from 'react';
import { Tag, Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, DatePicker, Skeleton } from 'antd';
import { EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { SelectGroup } from '../../../select/SelectGroup';
import { SelectCategory } from '../../../select/SelectCategory';
import { SelectAcademicYear4 } from '../../../select/SelectAcademicYear4';
import { SelectAcademicYear3 } from '../../../select/SelectAcademicYear3';


export default function AcademicInfo() {

    const [searchStudentForm] = Form.useForm();
    const fetchStudentListByClassConfigId = useStoreActions((state) => state.student.fetchStudentListByClassConfigId);
    const updateStudentSection = useStoreActions((state) => state.student.updateStudentSection);
    const updateStudentGroup = useStoreActions((state) => state.student.updateStudentGroup);
    const updatestudentCategroyValue = useStoreActions((state) => state.student.updatestudentCategroyValue);
    const studentListByClassConfigId = useStoreState((state) => state.student.studentListByClassConfigId);
    const updateStudentSession = useStoreActions((state) => state.student.updateStudentSession);

    const [selectedRowKeys, setselectedRowKeys] = useState([]);
    const [selectedValue, setselectedValue] = useState([]);
    const loading = useStoreState((state) =>  state.student.loading);
    

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const [datax,setData]= useState<any>([]);

    const searchStudentList = (value) => {
        setData(value);
        fetchStudentListByClassConfigId(value);
    }
    const columns = [
        {
            title: 'ID',
            dataIndex: 'customStudentId',
            key: 'customStudentId',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Student Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true,

        },
        {
            title: 'Class',
            dataIndex: 'className',
            key: 'className',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Shift',
            dataIndex: 'shiftName',
            key: 'shiftName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Section',
            dataIndex: 'sectionName',
            key: 'sectionName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Group',
            dataIndex: 'groupName',
            key: 'groupName',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Session',
            dataIndex: 'studentSession',
            key: 'studentSession',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Category',
            dataIndex: 'studentCategoryName',
            key: 'studentCategoryName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Roll',
            dataIndex: 'studentRoll',
            key: 'studentRoll',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Mobile',
            dataIndex: 'guardianMobile',
            key: 'guardianMobile',
            showOnResponse: true,
            showOnDesktop: true,
        },


    ];

    const [isModalVisible1, setIsModalVisible1] = useState(false);
    const [isModalVisible2, setIsModalVisible2] = useState(false);
    const [isModalVisible3, setIsModalVisible3] = useState(false);
    const [isModalVisible4, setIsModalVisible4] = useState(false);


    const [section, setSection] = useState<any>();


    const updateSectionFormSubmit = (value) => {
        value.identificationIds = selectedValue.map((item: any) => item.identificationId);
        let payload = {
            key: datax,
            value: value
        }
        updateStudentSection(payload);
        setselectedValue([]);
        setselectedRowKeys([]);
        setIsModalVisible1(false);
    }

    const updateGroupFormSubmit = (value) => {
        value.identificationIds = selectedValue.map((item: any) => item.identificationId);
        let payload = {
            key: datax,
            value: value
        }
        console.log(payload);
        updateStudentGroup(payload);
        setselectedValue([]);
        setselectedRowKeys([]);
        setIsModalVisible2(false);

    }

    const updateCategoryFormSubmit = (value) => {
        value.identificationIds = selectedValue.map((item: any) => item.identificationId);
        let payload = {
            key: datax,
            value: value
        }
        console.log(payload);
        updatestudentCategroyValue(payload);
        setselectedValue([]);
        setselectedRowKeys([]);
        setIsModalVisible3(false);
    }

    const updateSession = (value) => {
        value.studentIds = selectedValue.map((item: any) => item.studentId);
        value.academicYear=datax.academicYear;
        value.classconfigid=datax.classconfigid;

        updateStudentSession(value);
        setselectedValue([]);
        setselectedRowKeys([]);
        setIsModalVisible4(false);
    }

    const showModal1 = () => {
        let checkSelectedRow = selectedRowKeys.length;
        if (checkSelectedRow > 0) {
            setIsModalVisible1(true)
        } else {
            notification.error({ message: 'Select the table row first' });
        }
    }
    const showModal2 = () => {
        let checkSelectedRow = selectedRowKeys.length;
        if (checkSelectedRow > 0) {
            setIsModalVisible2(true)
        } else {
            notification.error({ message: 'Select the table row first' });
        }
    }
    const showModal3 = () => {
        let checkSelectedRow = selectedRowKeys.length;
        if (checkSelectedRow > 0) {
            setIsModalVisible3(true)
        } else {
            notification.error({ message: 'Select the table row first' });
        }
    }

    const showModal4 = () => {
        let checkSelectedRow = selectedRowKeys.length;
        if (checkSelectedRow > 0) {
            setIsModalVisible4(true)
        } else {
            notification.error({ message: 'Select the table row first' });
        }
    }

    const [updateSectionForm] = Form.useForm();
    const [updateGroupForm] = Form.useForm();
    const [updateCategoryForm] = Form.useForm();
    const [updateSessionForm] = Form.useForm();


    return (
        <>
            <Card title="Student Academic Info Update">
                <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 4 }} xl={{ span: 20, offset: 4 }}>
                        <Form
                            layout="vertical"
                            id="sessionYearInfo"
                            onFinish={searchStudentList}
                            form={searchStudentForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="academicYear"
                                        label="Academic Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]}
                                    >
                                        <SelectAcademicYear3 />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="classconfigid"
                                        label="Section"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select section" },
                                        ]}
                                    >
                                        <SelectClassConfig onChange={e => setSection(e)} style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>

                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                        Search
                                    </Button>

                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                
                <Skeleton loading={loading} paragraph={{rows:10}} />
                {studentListByClassConfigId !== null &&
                    <>

                        <div className="datatable-responsive-demo" style={{ width: "100%", overflow: "auto" }}>

                            <Table
                                antTableProps={{
                                    showHeader: true,
                                    rowSelection: rowSelection,
                                    columns,
                                    dataSource: studentListByClassConfigId,
                                    filterData: studentListByClassConfigId,
                                    pagination: false,
                                    bordered: true,
                                    rowKey: "studentId",
                                }}
                                mobileBreakPoint={768}

                            />
                        </div>

                        <Row >
                            <Col span="24">
                                <Space style={{ float: 'right' }}>
                                    <Button type="primary" onClick={() => showModal1()}  >
                                        Update Section
                                    </Button>
                                    <Button type="primary" onClick={() => showModal2()}  >
                                        Update Group
                                    </Button>
                                    <Button type="primary" onClick={() => showModal3()}  >
                                        Update Category
                                    </Button>
                                    <Button type="primary" onClick={() => showModal4()}  >
                                        Update Session
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </>
                }
            </Card>
            <Modal
                title="Update Section"
                visible={isModalVisible1}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => {setIsModalVisible1(false); updateSectionForm.resetFields()}}
                cancelText="Close"
                okText="Update"
                maskClosable={false}
                centered
                destroyOnClose
            >
                <Form
                    layout="vertical"
                    id="update"
                    onFinish={updateSectionFormSubmit}
                    form={updateSectionForm}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="classConfigId"
                                label="Section"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Select section" },
                                ]}
                            >
                                <SelectClassConfig style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Modal
                title="Update Group"
                visible={isModalVisible2}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => {setIsModalVisible2(false); updateGroupForm.resetFields()}}
                cancelText="Close"
                okText="Update"
                centered
                destroyOnClose
            >
                <Form
                    layout="vertical"
                    id="update"
                    onFinish={updateGroupFormSubmit}
                    form={updateGroupForm}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="groupId"
                                label="Group"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Select section" },
                                ]}
                            >
                                <SelectGroup style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Modal
                title="Update Category"
                visible={isModalVisible3}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => {setIsModalVisible3(false); updateCategoryForm.resetFields()}}
                cancelText="Close"
                okText="Update"
                centered
                destroyOnClose
            >
                <Form
                    layout="vertical"
                    id="update"
                    onFinish={updateCategoryFormSubmit}
                    form={updateCategoryForm}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="categoryId"
                                label="Categroy"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Select section" },
                                ]}
                            >
                                <SelectCategory style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

           
            <Modal
                title="Update Session"
                visible={isModalVisible4}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => {setIsModalVisible4(false)}}
                cancelText="Close"
                okText="Update"
                centered
                destroyOnClose
            >
                <Form
                    layout="vertical"
                    id="update"
                    onFinish={updateSession}
                    form={updateSessionForm}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="studentSession"
                                label="Session"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Write Session" },
                                ]}
                            >
                                <Input style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}