import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import Column from 'rc-table/lib/sugar/Column';
import { EditOutlined, SwitcherOutlined } from '@ant-design/icons';
import TableView from '../../../contents/AntTableResponsive';
const { Option } = Select;

export default function PartnerSchoolList() {
    const fetchInstiuteListcore = useStoreActions((state)=> state.customerSupport.fethPartnerInstituteList);
    const instiuteListcore = useStoreState((state) => state.customerSupport.partnerInstituteList);
    const goToInstitute = useStoreActions((state)=> state.auth.goToPartnerInstitute);
   
    useEffect(() => {
        fetchInstiuteListcore();
    }, []);

    const columns = [
      {title: 'Go To', dataIndex: 'partnerId', key: 'partnerId', showOnResponse: true, showOnDesktop: true, render: (text: any, record: any, index) => (
        <Space size="middle">
            <Button type='primary' icon={<EditOutlined />} onClick={() => {
               let payLoad = {
                partnerId  : record.partnerId,
                instituteId: record.instituteId,
               };
               goToInstitute(payLoad);
            }}/>
        </Space>
        )},
        { title: 'Institute ID', dataIndex: 'instituteId', key: "instituteId", showOnResponse: true, showOnDesktop: true  },
        { title: 'Institute Name', dataIndex: 'instituteName', key: "instituteName", showOnResponse: true, showOnDesktop: true  },
        { title: 'Address', dataIndex: 'address', key: "address", showOnResponse: true, showOnDesktop: true  },
        { title: 'Contact Number', dataIndex: 'contactNumber', key: "contactNumber", showOnResponse: true, showOnDesktop: true  },
        { title: 'Partner', dataIndex: 'partnerName', key: "partnerName", showOnResponse: true, showOnDesktop: true  },
        { title: 'Package', dataIndex: 'packageName', key: "packageName", showOnResponse: true, showOnDesktop: true  },
        { title: 'Total Student', dataIndex: 'totalStudent', key: "totalStudent", showOnResponse: true, showOnDesktop: true  },
        { title: 'Institute Status', dataIndex: 'instituteStatus', key: "instituteStatus", showOnResponse: true, showOnDesktop: true  },
      
    ];

    return(
        <>
           <Card title="Institute List">
           <Row className="m-t-mo-30">
                <Col span={24}>
                    <div className="datatable-responsive-demo">
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns,
                                dataSource: instiuteListcore,
                                filterData: instiuteListcore,
                                pagination: true,
                                bordered: true,
                                rowKey: "instituteId",
                            }}
                            mobileBreakPoint={768}
                        />
                    </div>
                </Col>
            </Row>
           </Card>

        </>
    )
}