import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Tag, Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, DatePicker, Skeleton } from 'antd';
import { EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import { bloodData, fatheroccupationdata, genederData, medium, motheroccupationdata, quotaData, religionData } from './admissiondata';
const { Option } = Select;


export default function OnlineAdmissionInfoEdit() {

    const [searchStudentForm] = Form.useForm();
    const [updateForm] = Form.useForm();
    const updateApplicantInfo = useStoreActions((state) => state.onlineAdmission.updateApplicantInfo);
    const fetchfindSingleApplicant = useStoreActions((state) => state.onlineAdmission.fetchfindSingleApplicant);
    const findSingleApplicant = useStoreState((state) => state.onlineAdmission.findSingleApplicant);;
    const loading = useStoreState((state) => state.onlineAdmission.loading);

    const searchOnlineAdmission = (value) => {
        fetchfindSingleApplicant(value.registrationId);
    }

    const updateOnlineAdmission = (values) => {
        console.log("sdwd")
        let postData = {
            "academicYear": 2023,
            "applicantId": findSingleApplicant?.applicantId,
            "bloodGroup": values.bloodGroup,
            "fatherName": values.fatherName,
            "fatherWorkPlace": values.fatherWorkPlace,
            "studentImageFileContent": imageFileContent === undefined ? "R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" : imageFileContent,
            "studentImageName": imageFileName === undefined ? "no_image.png" : imageFileName,
            "studentImageFileSave": imageFileSave,
            "fatherImageFileContent": imageFileContentFather === undefined ? "R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" : imageFileContentFather,
            "fatherImageName": imageFileNameFather === undefined ? "no_image.png" : imageFileNameFather,
            "fatherImageFileSave": imageFileSaveFather,
            "motherImageFileContent": imageFileContentMother === undefined ? "R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" : imageFileContentMother,
            "motherImageName": imageFileNameMother === undefined ? "no_image.png" : imageFileNameMother,
            "motherImageFileSave": imageFileSaveMother,
            "localGuardianMobile": values.localGuardianMobile,
            "localGuardianEmail": values.localGuardianEmail,
            "instituteId": findSingleApplicant?.instituteId,
            "motherName": values.motherName,
            "dateOfBirth": moment(values.birthDate).format("YYYY-MM-DD"),
            "gender": values.gender,
            "lastInstituteName": values.lastInstituteName,
            "mobileNumber": values.mobileNumber,
            "applicantName": values.applicantName,
            "admissionFileName": values.admissionFileName,
            "medium": values.medium,
            "religion": values.religion,
            "quota": values.quota,
            "birthcertificateNo": values.birthCertificateNo,
            "nationality": values.nationality,
            "autism": values.autism,
            "fatherMobile": values.fatherMobile,
            "fatherOccupation": values.fatherOccupation,
            "fatherIncome": values.fatherIncome,
            "fatherEducation": values.fatherEducation,
            "fatherDesignation": values.fatherDesignation,
            "motherWorkPlace": values.motherWorkPlace,
            "motherMobile": values.motherMobile,
            "motherNid": values.motherNid,
            "motherEducation": values.motherEducation,
            "motherDesignation": values.motherDesignation,
            "fatherNid": values.fatherNid,
            "motherOccupation": values.motherOccupation,
            "motherIncome": values.motherIncome,
            "localGuardianName": values.localGuardianName,
            /****Address objects***/
            "presentThanaId": presentThanaId,
            "presentAddress": values.presentAddress,
            "permanentAddress": values.permanentAddress,
            "permanentThanaId": permanentThanaId,
            "groupConfigId": findSingleApplicant?.groupId,
            "admissionCharge": findSingleApplicant?.admissionCharge,
            "serviceCharge": findSingleApplicant?.serviceCharge,
            "roll": values?.roll
            // other
          }
          updateApplicantInfo(postData);
    }

    const [districtList, setdistrictList] = useState<any>([]);
    const [thanaList, setPresentthanaList] = useState<any>([]);
    const [permanentThanaList, setPermanentThanaList] = useState<any>([]);
    const [presentThanaId, setPresentThanaId] = useState<any>('');
    const [permanentThanaId, setPermanentThanaId] = useState<any>('');
    const [presentDisId, setPresentDisId] = useState<any>('');
    const [permanentDisId, setPermanentDISId] = useState<any>('');
    useEffect(() => {
        if (findSingleApplicant !== null) {
           
            updateForm.setFieldsValue({
                applicantName: findSingleApplicant?.applicantName,
                fatherName: findSingleApplicant?.fatherName,
                motherName: findSingleApplicant?.motherName,

                medium: findSingleApplicant?.medium,
                fatherWorkPlace: findSingleApplicant?.fatherWorkPlace,
                motherWorkPlace: findSingleApplicant?.motherWorkPlace,
                fatherEducation: findSingleApplicant?.fatherEducation,
                fatherDesignation: findSingleApplicant?.fatherDesignation,
                motherEducation: findSingleApplicant?.motherEducation,
                motherDesignation: findSingleApplicant?.motherDesignation,
                localGuardianMobile: findSingleApplicant?.localGuardianMobile,
                localGuardianEmail: findSingleApplicant?.localGuardianEmail,
              
                religion: findSingleApplicant?.religion,
                gender: findSingleApplicant?.gender,
                lastInstituteName: findSingleApplicant?.lastInstituteName,
                mobileNumber: findSingleApplicant?.mobileNumber,
                bloodGroup: findSingleApplicant?.bloodGroup,
                quota: findSingleApplicant?.quota,
                birthCertificateNo: findSingleApplicant?.birthCertificateNo,
                nationality: findSingleApplicant?.nationality,
                autism: findSingleApplicant?.autism,
                fatherMobile: findSingleApplicant?.fatherMobile,
                fatherOccupation: findSingleApplicant?.fatherOccupation,
                fatherIncome: findSingleApplicant?.fatherIncome,
                fatherNid: findSingleApplicant?.fatherNid,
                roll: findSingleApplicant?.roll,
                birthDate: findSingleApplicant.dateOfBirthString == null ? null : moment(findSingleApplicant.dateOfBirthString, 'DD-MM-YYYY'),

                motherMobile: findSingleApplicant?.motherMobile,
                motherNid: findSingleApplicant?.motherNid,
                motherOccupation: findSingleApplicant?.motherOccupation,
                motherIncome: findSingleApplicant?.motherIncome,

                presentAddress: findSingleApplicant?.presentAddress,

                permanentAddress: findSingleApplicant?.permanentAddress,
                permanentThanaId: findSingleApplicant?.permanentThanaId,
                localGuardianName: findSingleApplicant?.localGuardianName,
                presentDistrict: findSingleApplicant?.presentDistrict,
                presentThanaId: findSingleApplicant?.presentThanaId,
                permanentDistrict: findSingleApplicant?.permanentDistrictId,
            });
            setTimeout(() => {
                setPresentDisId(findSingleApplicant?.presentDistrictId);
                setPermanentDISId(findSingleApplicant?.permanentDistrictId);
                fetchThanaPerm(findSingleApplicant?.permanentDistrictId, findSingleApplicant?.permanentThanaId);
                fetchpresentThanaId(findSingleApplicant?.presentDistrictId, findSingleApplicant?.presentThanaId);
                updateForm.setFieldsValue({
                    presentDistrictId: findSingleApplicant?.presentDistrictId,
                    permanentDistrictId: findSingleApplicant?.permanentDistrictId,
                })
            }, 1500);
        }

    }, [findSingleApplicant])

    async function fetchThanaPerm(disId, permanentThanaIdx) {
        let thanaReponse: any = await fetch(
            `${process.env.REACT_APP_API_ROOT}/location/info/thana-list/by/district-id?districtId=${disId}`
        );
        thanaReponse = await thanaReponse.json();
        setPermanentThanaList(thanaReponse?.item);
        setTimeout(() => {
            setPermanentThanaId(permanentThanaIdx);
            updateForm.setFieldsValue({
                permanentThanaId: permanentThanaIdx,
            })
        }, 1000);
    }


    async function fetchpresentThanaId(disId, presentThanaIdx) {

        let thanaReponse: any = await fetch(
            `${process.env.REACT_APP_API_ROOT}/location/info/thana-list/by/district-id?districtId=${disId}`
        );
        thanaReponse = await thanaReponse.json();
        setPresentthanaList(thanaReponse?.item);
        setTimeout(() => {
            setPresentThanaId(presentThanaIdx);
            updateForm.setFieldsValue({
                presentThanaId: presentThanaIdx,
            })
        }, 1000);

    }

    const [imageFileContent, setimageFileContent] = useState<any>();
    const [imageFileSave, setimageFileSave] = useState<any>(false);
    const [imageFileName, setimageFileName] = useState<any>(undefined);

    const [imageFileContentFather, setimageFileContentFather] = useState<any>();
    const [imageFileSaveFather, setimageFileSaveFather] = useState<any>(false);
    const [imageFileNameFather, setimageFileNameFather] = useState<any>(undefined);

    const [imageFileContentMother, setimageFileContentMother] = useState<any>();
    const [imageFileSaveMother, setimageFileSaveMother] = useState<any>(false);
    const [imageFileNameMother, setimageFileNameMother] = useState<any>(undefined);

    function clearFileInput(ctrl) {
        try {
            ctrl.value = null;
        } catch (ex) { }
        if (ctrl.value) {
            ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
        }
    }
    const imageUpload = (e) => {
        e.preventDefault();
        const reader: any = new FileReader();
        const file = e.target.files[0];
        if (file?.size > 600000) {
            // file.target.value = null;
            clearFileInput(document.getElementById("imgfile"));
            setimageFileSave(false);
            setimageFileContent('');
            setimageFileName(undefined);
            alert("Image size should be less than 600KB");
            return;
        };
        if (reader !== undefined && file !== undefined) {
            setimageFileSave(true);

            reader.onloadend = () => {
                setimageFileName(file.name)
                setimageFileContent(reader.result.split(',')[1])
            }
            reader.readAsDataURL(file);
        }
    };

    const imageUploadFather = (e) => {
        e.preventDefault();
        const reader: any = new FileReader();
        const file = e.target.files[0];
        if (file?.size > 600000) {
            // file.target.value = null;
            clearFileInput(document.getElementById("imgfileFather"));
            setimageFileSaveFather(false);
            setimageFileContentFather('');
            setimageFileNameFather(undefined);
            alert("Image size should be less than 600KB");
            return;
        };
        if (reader !== undefined && file !== undefined) {
            setimageFileSaveFather(true);
            reader.onloadend = () => {
                setimageFileNameFather(file.name)
                setimageFileContentFather(reader.result.split(',')[1])
            }
            reader.readAsDataURL(file);
        }
    };

    const imageUploadMother = (e) => {
        e.preventDefault();
        const reader: any = new FileReader();
        const file = e.target.files[0];
        if (file?.size > 600000) {
            // file.target.value = null;
            clearFileInput(document.getElementById("imgfileMother"));
            setimageFileSaveMother(false);
            setimageFileContentMother('');
            setimageFileNameMother(undefined);
            alert("Image size should be less than 600KB");
            return;
        };
        if (reader !== undefined && file !== undefined) {
            setimageFileSaveMother(true);
            reader.onloadend = () => {
                setimageFileNameMother(file.name)
                setimageFileContentMother(reader.result.split(',')[1])
            }
            reader.readAsDataURL(file);
        }
    };





    useEffect(() => {
        async function fetchMyAPI() {
            let disReponse: any = await fetch(`${process.env.REACT_APP_API_ROOT}/location/info/district-list`)
            disReponse = await disReponse.json()
            setdistrictList(disReponse?.item)
        }

        fetchMyAPI();

    }, []);

    async function handlePresentDistrict(evt) {
        setPresentDisId(evt)
        setPresentThanaId(null);
        updateForm.setFieldsValue({
            presentThanaId: null,
        })
        let thanaReponse: any = await fetch(`${process.env.REACT_APP_API_ROOT}/location/info/thana-list/by/district-id?districtId=${evt}`)
        thanaReponse = await thanaReponse.json()
        setPresentthanaList(thanaReponse?.item)
    }

    async function handlePresentThana(evt) {
        setPresentThanaId(evt)
        updateForm.setFieldsValue({
            presentThanaId: evt,
        })
    }

    async function permanenThandleThana(evt) {
        setPermanentThanaId(evt)
        updateForm.setFieldsValue({
            permanentThanaId: evt,
        })
        // console.log(evt.target.value)
    }

    async function handlePermanentDistrict(evt) {
        setPermanentDISId(evt)
        setPermanentThanaId(null);
        updateForm.setFieldsValue({
            permanentThanaId: null,
        })
        let thanaReponse: any = await fetch(`${process.env.REACT_APP_API_ROOT}/location/info/thana-list/by/district-id?districtId=${evt}`)
        thanaReponse = await thanaReponse.json()
        setPermanentThanaList(thanaReponse?.item)
    }

    return (
        <>
            <Card title="Student Online Admission Info Edit">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                        <Form
                            layout="vertical"
                            id="onlineAdmission"
                            onFinish={searchOnlineAdmission}
                            form={searchStudentForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>

                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                    <Form.Item
                                        name="registrationId"
                                        label="Registration ID"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please write registration id" },
                                        ]}
                                    >
                                        <Input placeholder='Registration id' />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>

                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                        Search
                                    </Button>

                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>

                <Skeleton loading={loading} paragraph={{ rows: 4 }} />
                {findSingleApplicant !== null &&

                    <Form
                        layout="vertical"
                        id="onlineAdmissionUpdate"
                        onFinish={updateOnlineAdmission}
                        form={updateForm}
                    >
                        <Row>
                            <Col span={24}>
                                <h2>Basic Information</h2>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="applicantName"
                                    label="Applicant Name"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please enter applicant name" },
                                    ]}
                                >
                                    <Input placeholder="Applicant name" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="medium"
                                    label="Medium"
                                    className="title-Text"
                                >
                                    <Select
                                        placeholder="Select Medium"
                                    >
                                        {medium?.map((value) => (
                                            <Option key={value} value={value}  >
                                                {value}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="gender"
                                    label="Gender"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please enter select gender" },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select Gender"
                                    >
                                        {genederData?.map((value) => (
                                            <Option key={value} value={value}  >
                                                {value}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="mobileNumber"
                                    label="Student Mobile"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please enter Student Mobile" },
                                    ]}
                                >
                                    <Input placeholder="Student Mobile" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="religion"
                                    label="Religion"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select religion" },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select Religion"
                                    >
                                        {religionData?.map((value) => (
                                            <Option key={value} value={value}  >
                                                {value}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="bloodGroup"
                                    label="Blood Group"
                                    className="title-Text"
                                >
                                    <Select
                                        placeholder="Select Blood Group"
                                    >
                                        {bloodData?.map((value) => (
                                            <Option key={value} value={value}  >
                                                {value}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="quota"
                                    label="Quota"
                                    className="title-Text"
                                >
                                    <Select
                                        placeholder="Select Quota"
                                    >
                                        {quotaData?.map((value) => (
                                            <Option key={value} value={value}  >
                                                {value}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="birthCertificateNo"
                                    label="Birth Certificate No"
                                    className="title-Text"
                                >
                                    <Input placeholder="Birth Certificate No" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="nationality"
                                    label="Nationality"
                                    className="title-Text"
                                >
                                    <Input placeholder="Nationality" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="autism"
                                    label="Autism"
                                    className="title-Text"
                                >
                                    <Select
                                        placeholder="Select Autism"
                                    >
                                        <Option value={0}>No</Option>
                                        <Option value={1}>Yes</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="lastInstituteName"
                                    label="Last Institute Name"
                                    className="title-Text"
                                >
                                    <Input placeholder="Last Institute Name" />
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="roll"
                                    label="Roll"
                                    className="title-Text"
                                >
                                    <Input placeholder="Roll" />
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="birthDate"
                                    label="Date of Birth:"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please Enter Birth Date" },
                                    ]}
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"YYYY-MM-DD"} />
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 2 }} xl={{ span: 2 }}>
                                <img className="studentImageLink" src={findSingleApplicant?.studentImageLink} height={50} width={50} alt="studentImageLink" style={{ marginTop: 20 }} /> <br />
                                Student Photo
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <input id="imgfile" style={{ marginTop: 20 }}  name="file" type="file" accept="image/*" onChange={(e) => {
                                    imageUpload(e)
                                }} />
                            </Col>
                            <Col span={24}>
                            <br /> <br />
                                <h2> Father's Information</h2>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="fatherName"
                                    label="Father Name"
                                    className="title-Text"
                                >
                                    <Input placeholder="Father Name" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="fatherMobile"
                                    label="Father Mobile"
                                    className="title-Text"
                                >
                                    <Input placeholder="Father Mobile" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="fatherNid"
                                    label="Father NID"
                                    className="title-Text"
                                >
                                    <Input placeholder="Father nid" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="fatherOccupation"
                                    label="Father Occupation"
                                    className="title-Text"
                                >
                                    <Select
                                        placeholder="Select Father Occupation"
                                    >
                                        {fatheroccupationdata?.map((value) => (
                                            <Option key={value} value={value}  >
                                                {value}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="fatherIncome"
                                    label="Father Income"
                                    className="title-Text"
                                >
                                    <Input placeholder="Father Income" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="fatherWorkPlace"
                                    label="Father Workplace"
                                    className="title-Text"

                                >
                                    <Input placeholder="Father Workplace" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="fatherEducation"
                                    label="Father Education"
                                    className="title-Text"
                                >
                                    <Input placeholder="Father Education" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="fatherDesignation"
                                    label="Father Designation"
                                    className="title-Text"
                                >
                                    <Input placeholder="Father Designation" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 2 }} xl={{ span: 2 }}>
                                <img className="fatherImageLink" src={findSingleApplicant?.fatherImageLink} height={50} width={50} alt="fatherImageLink" style={{ marginTop: 20 }} />
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <input id="imgfile" style={{ marginTop: 20 }} name="file" type="file" accept="image/*" onChange={(e) => {
                                    imageUploadFather(e)

                                }} />
                            </Col>
                            <Col span={24}>
                                <h2> Mother's Information</h2>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="motherName"
                                    label="Mother Name"
                                    className="title-Text"
                                >
                                    <Input placeholder="Mother Name" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="motherMobile"
                                    label="Mother Mobile"
                                    className="title-Text"
                                >
                                    <Input placeholder="Mother Mobile" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="motherNid"
                                    label="Mother NID"
                                    className="title-Text"
                                >
                                    <Input placeholder="Mother nid" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="motherOccupation"
                                    label="Mother Occupation"
                                    className="title-Text"
                                >
                                    <Select
                                        placeholder="Select Mother Occupation"
                                    >
                                        {motheroccupationdata?.map((value) => (
                                            <Option key={value} value={value}  >
                                                {value}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="motherIncome"
                                    label="Mother Income"
                                    className="title-Text"
                                >
                                    <Input placeholder="Mother Income" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="motherWorkPlace"
                                    label="Mother Workplace"
                                    className="title-Text"
                                >
                                    <Input placeholder="Mother Workplace" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="motherEducation"
                                    label="Mother Education"
                                    className="title-Text"
                                   
                                >
                                    <Input placeholder="Mother Education" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="motherDesignation"
                                    label="Mother Designation"
                                    className="title-Text"
                                >
                                    <Input placeholder="Mother Designation" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 2 }} xl={{ span: 2 }}>
                                <img className="motherImageLink" src={findSingleApplicant?.motherImageLink} height={50} width={50} alt="motherImageLink" style={{ marginTop: 20 }} />
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <input id="imgfile" style={{ marginTop: 20 }} name="file" type="file" accept="image/*" onChange={(e) => {
                                    imageUploadMother(e)

                                }} />
                            </Col>
                            <Col span={24}>
                                <h2>Present Address Information</h2>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="presentAddress"
                                    label="Present Address"
                                    className="title-Text"
                                >
                                    <Input placeholder="Present Address" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="presentDistrictId"
                                    label="District"
                                    className="title-Text"
                                >
                                    <Select
                                        placeholder="Select District"
                                        onChange={(e) => {
                                            handlePresentDistrict(e);
                                        }}
                                    >
                                        {districtList?.map((value) => (
                                            <Option key={value?.districtId} value={value?.districtId}  >
                                                {value?.districtName}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="presentThanaId"
                                    label="Thana"
                                    className="title-Text"
                                >
                                    <Select
                                        placeholder="Select Thana"
                                        onChange={(e) => {
                                            handlePresentThana(e);
                                        }}
                                    >
                                        {thanaList?.map((value) => (
                                            <Option key={value?.thanaId} value={value?.thanaId}  >
                                                {value?.thanaName}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <h2>Permanent Address Information</h2>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="permanentAddress"
                                    label="Permanent Address"
                                    className="title-Text"
                                >
                                    <Input placeholder="Permanent Address" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="permanentDistrictId"
                                    label="District"
                                    className="title-Text"
                                >
                                    <Select
                                        placeholder="Select District"
                                        onChange={(e) => {
                                            handlePermanentDistrict(e);
                                        }}
                                    >
                                        {districtList?.map((value) => (
                                            <Option key={value?.districtId} value={value?.districtId}  >
                                                {value?.districtName}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="permanentThanaId"
                                    label="Thana"
                                    className="title-Text"
                                >
                                    <Select
                                        placeholder="Select Thana"
                                        onChange={(e) => {
                                            permanenThandleThana(e);
                                        }}
                                    >
                                        {permanentThanaList?.map((value) => (
                                            <Option key={value?.thanaId} value={value?.thanaId}  >
                                                {value?.thanaName}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <h2>Legal Guardian Information (For Notify)</h2>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="localGuardianName"
                                    label="Local Guardian Name"
                                    className="title-Text"
                                >
                                    <Input placeholder="Local Guardian Name" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="localGuardianMobile"
                                    label="Local Guardian Mobile"
                                    className="title-Text"
                                >
                                    <Input placeholder="Local Guardian Mobile" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="localGuardianEmail"
                                    label="Local Guardian Email"
                                    className="title-Text"
                                >
                                    <Input placeholder="Local Guardian Email" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>

                                <Button type="primary" htmlType="submit" icon={<SaveOutlined />} >
                                    Update
                                </Button>

                            </Col>
                        </Row>
                    </Form>

                }

            </Card>
        </>
    )
}