
import { Card, Tabs } from 'antd';
import React, { useEffect, useState } from 'react'
import EmployeeSalaryGrade from './EmployeeSalaryConfig/EmployeeSalaryGrade.page';
import EmployeeSalaryManual from './EmployeeSalaryConfig/EmployeeSalaryManual.page';


const { TabPane } = Tabs;

export default function EmployeeSalary(props) {

    const [activeTab, setActiveTab] = React.useState<any>("1");

    return (
        <Card title="Employee Salary Configuration" >
            <Tabs defaultActiveKey="1" size={"large"} style={{ marginBottom: 32 }} onChange={(e) => { setActiveTab(e) }}>
                <TabPane tab="Grade" key="1">
                    {activeTab === "1" && <EmployeeSalaryGrade />}
                </TabPane>
                <TabPane tab="Manual" key="2">
                    {activeTab === "2" && <EmployeeSalaryManual />}
                </TabPane>

            </Tabs>
        </Card>
    )
}
