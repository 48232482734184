import { FileExcelOutlined, FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Form, Row, Skeleton, Space } from 'antd';
import React, { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import { Column } from '@ant-design/plots';
import TableView from '../../../contents/AntTableResponsive';

export default function PersonWiseInstitute() {

    const fetchinstituteTransactionstatus = useStoreActions((state) => state.customerSupport.fetchinstituteTransactionstatus);
    const instituteTransactionstatus = useStoreState((state) => state.customerSupport.instituteTransactionstatus);
    const loading = useStoreState((state) => state.customerSupport.loading);

    useEffect(() => {
        fetchinstituteTransactionstatus();
    }, []);

    const columns = [
        { title: 'Institute ID', dataIndex: 'instituteId', key: "instituteId", showOnResponse: true, showOnDesktop: true  },
        { title: 'Institute Name', dataIndex: 'instituteName', key: "instituteName", showOnResponse: true, showOnDesktop: true  },
        { title: 'PIP', dataIndex: 'partnerName', key: "partnerName", showOnResponse: true, showOnDesktop: true  },
        { title: 'Create Date', dataIndex: 'createDate', key: "createDate", showOnResponse: true, showOnDesktop: true  },
        { title: 'Transaction Start Date', dataIndex: 'firstTransactionDate', key: "firstTransactionDate", showOnResponse: true, showOnDesktop: true  },
        { title: 'Transaction Last Date', dataIndex: 'lastTransactionDate', key: "lastTransactionDate", showOnResponse: true, showOnDesktop: true  },
    ]

    return (
        <>
            <Card title="Institute Transaction Status">
                <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                {instituteTransactionstatus?.length > 0 && (
                    <div className="datatable-responsive-demo">
                    <TableView
                        antTableProps={{
                            showHeader: true,
                            columns,
                            dataSource: instituteTransactionstatus,
                            filterData: instituteTransactionstatus,
                            pagination: true,
                            bordered: true,
                            rowKey: "instituteId",
                        }}
                        mobileBreakPoint={768}
                    />
                </div>
                )}
            </Card>
        </>
    );
}
