import { Button, Card, Col, Row, Space, Tooltip } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import TableView from '../../../contents/AntTableResponsive';
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import { v4 as uuidv4 } from "uuid";
import { EditOutlined, FileTextOutlined } from '@ant-design/icons';
import ReactToPrint from 'react-to-print';
import logo from './logo.png';
declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes
    name?: string;
  }
}

const ComponentToPrint = React.forwardRef((props: any, ref: any) => {
  const { data, info } = props;
  //console.log(data);
  return (
    <div className='print-source' ref={ref} >
      <div className="cs-container" id="billinvoice">
        <div className="cs-invoice cs-style1">
          <div className="cs-invoice_in" id="download_section">
            <div className="cs-invoice_head cs-type1 cs-mb25">
              <div className="cs-invoice_left">
                <p className="cs-invoice_number cs-primary_color cs-mb0 cs-f16">
                  <b className="cs-primary_color">Invoice No:</b> #{data.billId}
                </p>
              </div>
              <div className="cs-invoice_right cs-text_right">
                <div className="cs-logo cs-mb5">
                  <img className="billinvoice" src={logo} alt="Logo" />
                </div>
              </div>
            </div>
            <div className="cs-invoice_head cs-mb10">
              <div className="cs-invoice_left">
                <b className="cs-primary_color">Invoice From:</b>
                <p>
                  Sheba Digital PLC <br />
                  House: 55, Road: 4/A Dhanmondi R/A <br />
                  Dhaka, 1209, Bangladesh <br />
                  billing@shebadigital.com <br />
                  09612-191919
                </p>
              </div>
              <div className="cs-invoice_right cs-text_right">
                <b className="cs-primary_color">Invoice To</b> <br />
                <span> {data.instituteName}</span> <br />
                <span> {data.instituteAddress}</span> <br />
                {/* <span> {info.instituteEmail}</span> <br /> */}
                <span> {data.contactNo}</span> <br />
                <span> Invoice Date: {data.billCreatedDate}</span> <br />
              </div>
            </div>

            <div className="cs-table cs-style2">
              <div className="billinvoice">
                <div className="cs-table_responsive">
                  <table>
                    <thead>
                      <tr className="cs-focus_bg">
                        <th className="cs-width_2 cs-semi_bold cs-primary_color">
                          Bill ID
                        </th>
                        <th className="cs-width_2 cs-semi_bold cs-primary_color">
                          Period
                        </th>
                        <th className="cs-width_4 cs-semi_bold cs-primary_color">
                          Total Student
                        </th>
                        <th className="cs-width_2 cs-semi_bold cs-primary_color">
                          Bill Rate
                        </th>
                        <th style={{ textAlign: "right" }} className="cs-width_2 cs-semi_bold cs-primary_color cs-text_right">
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="cs-width_2">
                          {data.billId} 
                        </td>
                        <td className="cs-width_2">
                          {data.billingMonth} ({data.billYear})
                        </td>
                        <td className="cs-width_4">
                          {data.totalStudent} 
                        </td>
                        <td className="cs-width_2">
                          {data.billRate} 
                        </td>
                        <td className="cs-width_2 cs-text_right cs-primary_color">
                          ৳{data.payableAmount}
                        </td>
                      </tr>

                      <tr>
                        <td className="cs-width_2"> </td>
                        <td className="cs-width_2"> </td>
                        <td className="cs-width_2"> </td>
                        <td className="cs-width_4">VAT</td>
                        <td className="cs-width_2 cs-text_right cs-primary_color">
                          ৳0
                        </td>
                      </tr>

                      <tr className="cs-focus_bg cs-no_border">
                      <td className="cs-width_2"> </td>
                      <td className="cs-width_4"> </td>
                      <td className="cs-width_4 cs-text_right cs-primary_color cs-semi_bold" colSpan={2}>
                          Total charges
                      </td>
                        <td className="cs-width_2 cs-text_right cs-primary_color cs-semi_bold">
                          ৳{data.payableAmount}
                        </td>
                      </tr>

                    </tbody>
                  </table>
                  <div style={{ marginTop: 40 }}>
                    {/* <h3> <strong>Payment Information:</strong> </h3>  */}
                    <b className="cs-primary_color">Bank Information for manual payment:</b>  <p></p>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', width: 300 }}>
                      <span>A/C Name</span>
                      <span>: Sheba Digital Limited</span>
                      <span>A/C Number</span>
                      <span>: 4410702000693</span>
                      <span>Branch Name</span>
                      <span>: Jigatola Branch</span>
                      <span>Routing Number</span>
                      <span>: 200262327</span>
                      <span>SWIFT Code</span>
                      <span>: BSONBDDH</span>
                      <span>Bank Name</span>
                      <span>: Sonali Bank PLC</span>
                    </div>
                  </div>

                  <div style={{ position: "absolute", bottom: 10 }}>
                    <p name="goHTML" className="c24" style={{ fontFamily: "Inter", borderBottom: "1px solid #eaeaea", paddingBottom: 5 }}><span className="c62">This is System Generated invoice. No signature is required. For any query
                      please email:</span><span className="c9 c86">&nbsp;billing@shebadigital.com</span></p><a id="t.abc6737e762182b95b3ce59d3d02f1237246fb5c" /><a id="t.4" />

                    <table className="c17">
                      <tbody>
                        <tr className="c57">
                          <td className="c38" colSpan={1} rowSpan={1}>
                            <p name="goHTML" className="c5"><span className="c34 c30 c71" style={{ fontFamily: "Inter" }}>House: 55, Road: 4/A,Dhanmondi R/A, Dhaka-1209
                              www.shebadigital.com</span></p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <p name="goHTML" className="c6"><span className="c67 c34 c30" /></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
});

const ComponentToPrintWrapper = ({ data, info }) => { // 1.
  const componentRef: any = useRef(); // 2.

  return (
    <div style={{ display: "flex" }}>
      <ReactToPrint
        trigger={() => <Button type='primary' icon={<FileTextOutlined />}>Invoice</Button>}
        content={() => componentRef.current}
      />
      <ComponentToPrint ref={componentRef} data={data} info={info} />
    </div>
  );
};

export default function SingleInstitutePayableBill() {

  const fetchPayableBillList = useStoreActions((state) => state.customerSupport.fetchSingleInstitutePayableBillList);
  const payableBillList = useStoreState((state) => state.customerSupport.singleInstitutePayableBillList);

  const instiuteInfoList = useStoreState((state) => state.masterSettings.instiuteInfoList);
  const fetchInstiuteInfoList = useStoreActions((state) => state.masterSettings.fetchInstiuteInfoList);
  useEffect(() => {
      fetchInstiuteInfoList();
  }, []);

  const [billId, setBillId] = useState<any>(null);

  useEffect(() => {
    fetchPayableBillList();
  }, []);

  const payInstituteOnlineBill = (value) => {
    const BASE_URL = process.env.REACT_APP_API_ROOT;
    var requesturl = BASE_URL + "/public/institute/bill/payment/spay?billId=" + value;
    setTimeout(() => {
      window.open(requesturl, '_blank');
    }, 200);

  };

  const columns = [

    { title: 'Institute Name', dataIndex: 'instituteName', key: "instituteName", showOnResponse: true, showOnDesktop: true },
    { title: 'Bill ID', dataIndex: 'billId', key: "billId", showOnResponse: true, showOnDesktop: true },
    { title: 'Bill Month', dataIndex: 'billingMonth', key: "billingMonth", showOnResponse: true, showOnDesktop: true },
    { title: 'Bill Year', dataIndex: 'billYear', key: "billYear", showOnResponse: true, showOnDesktop: true },
    { title: 'Total Student', dataIndex: 'totalStudent', key: "totalStudent", showOnResponse: true, showOnDesktop: true },
    { title: 'Bill Rate', dataIndex: 'billRate', key: "billRate", showOnResponse: true, showOnDesktop: true },
    { title: 'Bill Amount', dataIndex: 'payableAmount', key: "payableAmount", showOnResponse: true, showOnDesktop: true },
    { title: 'Bill Status', dataIndex: 'billStatusString', key: "billStatusString", showOnResponse: true, showOnDesktop: true },
    {
      title: 'Action', dataIndex: 'status', key: "status", showOnResponse: true, showOnDesktop: true, render: (text, record: any, index) =>
        <>
          <Space size="middle">
          <ComponentToPrintWrapper key={index} data={record} info={instiuteInfoList} />
            <Tooltip title="Edit">
              <Button type='primary' icon={<FileTextOutlined />} onClick={() => {
                payInstituteOnlineBill(record?.billId);
              }}
              >
                Pay 
              </Button>
            </Tooltip>
          </Space>
        </>
    },

  ]




  return (

    <>
      <Card title="Payable Bill">
        <Row className="m-t-mo-30">
          <Col span={24}>
            <div className="datatable-responsive-demo">
              <TableView
                antTableProps={{
                  showHeader: true,
                  columns,
                  dataSource: payableBillList,
                  filterData: payableBillList,
                  pagination: true,
                  bordered: true,
                  rowKey: "partnerId",
                }}
                mobileBreakPoint={768}
              />
            </div>
          </Col>
        </Row>
      </Card>

    </>
  )
}
