import { Select } from "antd";
import * as React from "react";
import { useStoreActions, useStoreState } from "../../store/hooks/easyPeasy";
const { Option } = Select;

export interface SelectPayRollGrade {
  onChange?: any;
  selected?: any;
  defaultSelected?: any;
  style?: any
}

export const SelectPayRollGrade = ({
  onChange,
  selected,
  defaultSelected,
  style
}: SelectPayRollGrade) => {
  const itemList = useStoreState((state) => state.payroll.salaryGradeList);
  const itemListFetch = useStoreActions((state) => state.payroll.fetchsalaryGradeList);

  React.useEffect(()=>{
    itemListFetch()
  },[])
  
  const onSelect = (id) => {
    if (itemList) {
      const value = itemList.find((item) => item.gradeId === id);
      onChange(value.gradeId);
    }
  };


  return (
    <Select
      onChange={onSelect}
      // loading={loading}
      showSearch
      // allowClear
      defaultValue={defaultSelected}
      value={selected}
      style={style}
      placeholder="Select Grade"
      filterOption={(input, option:any) =>
        option !== undefined &&
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {itemList ? (
        itemList.map((type, idx) => (
          <Option key={type.gradeId} value={type.gradeId}>
            {type.gradeName}
          </Option>
        ))
      ) : (
        <Option value="fetching">Fetching Grade</Option>
      )}
    </Select>
  );
};
