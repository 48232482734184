import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, TimePicker, Skeleton } from 'antd'
import { DeleteOutlined, DownloadOutlined, EditOutlined, FileExcelOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";
import moment from 'moment';
import { SelectPeriod } from '../../../select/SelectPeriod';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf, ldatepdf, lpowerdbypdf } from '../../../utils/pdf';
import { FilePdfOutlined } from '@ant-design/icons';
import ReactExport from "react-export-excel";
require('jspdf-autotable');


export default function SectionWiseMonthlyDetails() {

    const [form] = Form.useForm();
    const { Option } = Select;
    const format = 'HH:mm';
    const fetchStudentAttendanceAllPeriod = useStoreActions((state) => state.student.fetchStudentAttendanceAllPeriod);
    const fetchStudentMonthlySectionWiseSummaryDetails = useStoreActions((state) => state.student.fetchStudentMonthlySectionWiseSummaryDetails);
    const fetchStudentMonthlySectionWiseSummaryDetailsFromRollToRoll = useStoreActions((state) => state.student.fetchStudentMonthlySectionWiseSummaryDetailsFromRollToRoll);
    const loading = useStoreState((state) => state.student.loading)
    const studentMonthlySectionWiseSummaryDetails = useStoreState((state) => state.student.studentMonthlySectionWiseSummaryDetails)
    const sectionWiseMonthlySummary = (value) => {
        let postData: any = {
            classConfigId: value.classConfig,
            monthName: value.selectMOnth,
            periodId: value.period,
            year: value.sectionYear
        }
        if (value?.fromRoll != '' && value?.toRoll != '') {
            postData = {
                classConfigId: value.classConfig,
                monthName: value.selectMOnth,
                periodId: value.period,
                year: value.sectionYear,
                fromRoll: value.fromRoll,
                toRoll: value.toRoll,
            }
            fetchStudentMonthlySectionWiseSummaryDetailsFromRollToRoll(postData);
        } else fetchStudentMonthlySectionWiseSummaryDetails(postData);

    }

    useEffect(() => {
        fetchStudentAttendanceAllPeriod();
    }, []);


    const columns = [
        { title: 'ID', dataIndex: 'customStudentId', key: 'customStudentId', showOnResponse: true, showOnDesktop: true },
        { title: 'Student Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true },
        { title: 'Roll', dataIndex: 'studentRoll', key: 'studentRoll', showOnResponse: true, showOnDesktop: true },
        { title: 'Total Attendance', dataIndex: 'attendanceTaken', key: 'attendanceTaken', showOnResponse: true, showOnDesktop: true },
        { title: 'Present', dataIndex: 'totalPresent', key: 'totalPresent', showOnResponse: true, showOnDesktop: true },
        { title: 'Absent', dataIndex: 'totalAbsent', key: 'totalAbsent', showOnResponse: true, showOnDesktop: true },
    ];

    function exportPdf() {

        var doc = new jsPDF("l", "mm", "a4");

        var details = `Section Wise Monthly Summary Report`;
        pdfDataL(doc, "");
        doc.text(details, 150, 40, 'center');
        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = lpowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + ldatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };


        var col = [
            "ID",
            "Student Name",
            "Roll",
            "Total Attendance",
            "Present",
            "Absent"
        ].filter(Boolean);

        var rows: any = [];


        studentMonthlySectionWiseSummaryDetails.forEach(element => {
            var temp: any = [
                element.customStudentId,
                element.studentName,
                element.studentRoll,
                element.attendanceTaken,
                element.totalPresent,
                element.totalAbsent,
            ];
            rows.push(temp);
        });


        let first = doc.autoTable.previous;
        doc.autoTable(col, rows, {
            startY: 45,
            showHeader: "firstPage",
            theme: 'grid',
            headerStyles: {
                // fillColor: [105, 105, 105],
                // textColor: [255, 255, 255],
                // fontSize: 10
                lineWidth: .01,
                lineColor: [224, 224, 224]
            },
            columnStyles: {
                guardianMobile: {
                    halign: "left",
                    fontSize: 8,
                    columnWidth: 250
                }
            },
            addPageContent: pageContent,
        });


        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        doc.setPage(1 + doc.internal.getCurrentPageInfo().pageNumber - doc.autoTable.previous.pageCount);
        doc.save(details + ".pdf");

    }

    return <>
        <Card title="Section Wise Monthly Details">
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                    <Form
                        layout="vertical"
                        id="classConfigInfo"
                        form={form}
                        onFinish={sectionWiseMonthlySummary}
                    >
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Form.Item
                                    name="classConfig"
                                    label="Select Section"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select section" },
                                    ]}
                                >
                                    <SelectClassConfig />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Form.Item
                                    name="period"
                                    label="Period"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select period" },
                                    ]}
                                >
                                    <SelectPeriod />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Form.Item
                                    name="sectionYear"
                                    label="Select Year"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select year" },
                                    ]}
                                >
                                    <SelectAcademicYear />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Form.Item
                                    name="selectMOnth"
                                    label="Select Month"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select month" },
                                    ]}
                                >
                                    <Select placeholder="Select month">
                                        <Option key="1" value="January">January</Option>
                                        <Option key="2" value="February">February</Option>
                                        <Option key="3" value="March">March</Option>
                                        <Option key="4" value="April">April</Option>
                                        <Option key="5" value="May">May</Option>
                                        <Option key="6" value="June">June</Option>
                                        <Option key="7" value="July">July</Option>
                                        <Option key="8" value="August">August</Option>
                                        <Option key="9" value="September">September</Option>
                                        <Option key="10" value="October">October</Option>
                                        <Option key="11" value="November">November</Option>
                                        <Option key="12" value="December">December</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Form.Item
                                    name="fromRoll"
                                    label="From Roll"
                                    className="title-Text"
                                    initialValue={''}
                                >
                                    <Input placeholder='From Roll' />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Form.Item
                                    name="toRoll"
                                    label="To Roll"
                                    className="title-Text"
                                    initialValue={''}
                                >
                                    <Input placeholder='To Roll' />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                                <div style={{ float: "right" }}>
                                    <Space size="small" >
                                        <Button type="primary" htmlType="submit" className='mt-0' icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Skeleton loading={loading} paragraph={{ rows: 10 }} />
            <Row className='mt-30' style={{ display: studentMonthlySectionWiseSummaryDetails.length > 0 ? '' : 'none' }}>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                    <Table
                        antTableProps={{
                            showHeader: true,
                            columns: columns,
                            dataSource: studentMonthlySectionWiseSummaryDetails,
                            filterData: studentMonthlySectionWiseSummaryDetails,
                            pagination: true,
                            bordered: true,
                            rowKey: "customStudentId",
                        }}
                        mobileBreakPoint={768}

                    />
                </Col>
                <Col span={24}>
                    <Space size="small" style={{ float: "right" }}>
                        <Button type="primary" icon={<DownloadOutlined />} onClick={() => exportPdf()} >PDF Download</Button>
                    </Space>
                </Col>
            </Row>
        </Card>
    </>
}