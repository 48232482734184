import { DeleteOutlined, EditOutlined, SaveOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, Modal, Popconfirm, Row, Select, Space, Tooltip, Upload, notification } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";


export default function SalarySheetCreate(props) {

    const [updateForm] = Form.useForm();

    const employeeSalaryConfigurationList = useStoreState((state) => state.staff.employeeSalaryConfigurationList);
    const fetchemployeeSalaryConfigurationList = useStoreActions((state) => state.staff.fetchemployeeSalaryConfigurationList);
    const createSalarySheet = useStoreActions((state) => state.staff.createSalarySheet);

    useEffect(() => {
        fetchemployeeSalaryConfigurationList();
    }, [])


    const updateFomrSubmit = (value) => {
        let payload = {
            "bonusCalculation": value?.bonusCalculation,
            "month": value?.month,
            "staffIds": selectedValue?.map(item => item?.staffId),
            "year": value?.year
        }

        createSalarySheet(payload)
        //updateDesignation(postdata);
        setIsModalVisible(false);
        setTimeout(() => {
            setselectedRowKeys([]);
            setselectedValue([]);
        }, 1000);
    }
    const currentyear = new Date().getFullYear();
    const optionsYear = [
        { value: currentyear - 1, label: currentyear - 1 },
        { value: currentyear, label: currentyear },
        { value: currentyear + 1, label: currentyear + 1 }
    ];

    const optionsMonth = [
        { value: "January", label: "January" },
        { value: "February", label: "February" },
        { value: "March", label: "March" },
        { value: "April", label: "April" },
        { value: "May", label: "May" },
        { value: "June", label: "June" },
        { value: "July", label: "July" },
        { value: "August", label: "August" },
        { value: "September", label: "September" },
        { value: "October", label: "October" },
        { value: "November", label: "November" },
        { value: "December", label: "December" }

    ]
    const [staffId, setstaffId] = useState<any>();

    const columns = [

        {
            title: 'Serial',
            dataIndex: 'customStaffId',
            key: 'customStaffId',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Name',
            dataIndex: 'staffName',
            key: 'staffName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Designation',
            dataIndex: 'designation',
            key: 'designation',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Bank Name',
            dataIndex: 'bankName',
            key: 'bankName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: "Branch Name ",
            dataIndex: 'branchName',
            key: 'branchName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: "Acoount No",
            dataIndex: 'accountNo',
            key: 'accountNo',
            showOnResponse: true,
            showOnDesktop: true
        },

    ];
    const [month, setMonth] = useState<any>('');
    const [year, setYear] = useState<any>('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const openModal = () => {

        if (selectedValue.length === 0) {
            notification.error({ message: "Please select staff" });
            return;
        }

        setIsModalVisible(true)
    }

    return (
        <>
            <Card title="Salary Sheet Create" >
                <div className="datatable-responsive">
                    <Table
                        antTableProps={{
                            showHeader: true,
                            columns,
                            dataSource: employeeSalaryConfigurationList,
                            pagination: false,
                            bordered: true,
                            rowKey: 'staffId',
                            rowSelection: rowSelection,
                        }}
                        mobileBreakPoint={768}
                    />
                </div>
                <br />
                <Space size="middle" style={{ float: 'right' }}>
                    <Button type="primary" icon={<SettingOutlined />} onClick={() => openModal()}> Process</Button>
                </Space>
            </Card>

            <Modal
                title="Update"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => { setIsModalVisible(false); updateForm.resetFields() }}
                cancelText="Close"
                okText="Update"
                centered
            >
                <Form
                    layout="vertical"
                    onFinish={updateFomrSubmit}
                    id="update"
                    form={updateForm}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item
                                name="year"
                                label="Year:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select Year" },
                                ]}
                            >
                                <Select allowClear placeholder="Select Year" options={optionsYear} onChange={(e) => setYear(e)} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item
                                name="month"
                                label="Month:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select month" },
                                ]}
                            >
                                <Select allowClear placeholder="Select Month" options={optionsMonth} onChange={(e) => setMonth(e)} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item
                                name="bonusCalculation"
                                label="Bonus Calculation:"
                                valuePropName="checked"
                                className="title-Text"
                                initialValue={false}
                            >
                                <Checkbox>Bonus Calculation</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

            </Modal>
        </>
    )
}
