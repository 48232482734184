import { DeleteOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import {
    Button,
    Card,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    notification,
    Popconfirm,
    Row,
    Space,
    Table,
    Tooltip,
} from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { SelectLedgerPayment } from "../../../select/SelectLedgerPayment";
import { SelectLedgerCashEqui } from "../../../select/SelectLedgerCashEqui";
import { useStoreActions, useStoreState } from "../../../../store/hooks/easyPeasy";

export default function Payment(props) {

    const doPaymentVoucher = useStoreActions((state) => state.ledger.doPaymentVoucher);
    const download = useStoreActions((state) => state.ledger.download);
    const trnid = useStoreState((state) => state.ledger.trnid);

    const [tableData, setTableData] = useState<any>([
        {
            key: uuidv4(),
            ledgerId: null,
            ledgerAmount: null,
        },
    ]);

    const columns = [
        {
            title: "Payment For",
            render: (text: any, record: any, index) => (
                <SelectLedgerPayment
                    onChange={onchangeSelect("ledgerId", record, index)}
                    selected={record.ledgerId}
                    style={{ width: 250 }}
                />
            ),
        },
        {
            title: "Amount",
            render: (text: any, record: any, index) => (
                <InputNumber
                    onChange={onchangeValue("ledgerAmount", record, index)}
                    value={record.ledgerAmount}
                    placeholder="Ledger Amount"
                    style={{ width: 250 }}
                ></InputNumber>
            ),
        },
        {
            title: "Action",
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Add">
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => addItem(record)}
                        />
                    </Tooltip>
                    <Tooltip title="Delete">
                        <Popconfirm
                            title="Are you sure to delete this?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => deleteItem(record?.key)}
                        >
                            <Button danger icon={<DeleteOutlined />} />
                        </Popconfirm>
                    </Tooltip>
                </Space>
            ),
        },
    ];

    const onchangeSelect: any =
        (key, data, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const newData = [...tableData];
            newData[index][key] = e;
            setTableData(newData);
        };
    function addItem(record) {
        if (record.ledgerId == null || record.ledgerAmount == null) {
            notification.error({ message: "Please add ledger and amount" });
        } else {
            let tem = {
                key: uuidv4(),
                ledgerId: null,
                ledgerAmount: null,
            };
            const newData = [...tableData, tem];
            setTableData(newData);
        }
    }

    function deleteItem(key) {
        let temp = tableData.filter((item) => item.key !== key);
        setTableData(temp);
    }

    const onchangeValue: any =
        (key, data, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const newData = [...tableData];
            newData[index][key] = e;
            setTableData(newData);
        };



    const [form] = Form.useForm();

    const [totalAmount, settotalAmount] = useState(0);

    useEffect(() => {
        let value = tableData.reduce(function (acc, obj) {
            return acc + obj.ledgerAmount;
        }, 0);
        settotalAmount(value.toFixed(3));
        form.setFieldsValue({
            totalAmount: value.toFixed(3),
        });
    }, [tableData]);

    const submitFom = (value) => {
        if (totalAmount == 0) {
            notification.error({ message: "Total amount cannot be zero" })
        } else {
            let requestDetailses = tableData.map(function (item) {
                return {
                    ledgerId: parseInt(item.ledgerId),
                    ledgerAmount: parseFloat(item.ledgerAmount),
                }
            });
            let trnDate = moment(value?.date).format("YYYY-MM-DD");
            var postData = {
                requestDetailses: requestDetailses,
                paymetOrReceiptLedgerId: cashequi,
                tranDate: trnDate,
                trnAmount: totalAmount,
                voucherNo: value.voucherNo,
                voucherNote: value.note
            };
            //console.log(postData);
            doPaymentVoucher(postData);
            setTableData([{key: uuidv4(),ledgerId: null, ledgerAmount: null,}]);  
            form.resetFields();
        }

    };

    const [cashequi, setcashequi] = useState<any>();

    const onchangecashequi = (value) => {
        setcashequi(value)
    }


    return (
        <Card title="Payment Transaction">
         <Card title="Add Payment Transaction">
            <Table 
             bordered={true} pagination={false} dataSource={tableData} columns={columns} />
            </Card>
            <Card title="Transaction Payment Form">
                <Form
                    layout="vertical"
                    onFinish={submitFom}
                    id="basic-info"
                    form={form}
                >
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="totalAmount"
                                label="Total Amount:"
                                className="title-Text"
                                rules={[
                                    { required: false, message: "Please input ledger name" },
                                ]}
                            >
                                <Input
                                    placeholder="Total Amount"
                                    disabled
                                    value={totalAmount}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="date"
                                label="Date:"
                                className="title-Text"
                                initialValue={moment((new Date()).toISOString())}
                                rules={[
                                    { required: true, message: "Please select date" },
                                ]}
                            >
                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={'DD/MM/YYYY'} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="voucherNo"
                                label="Voucher No:"
                                className="title-Text"
                                rules={[
                                    { required: false, message: "Please input ledger name" },
                                ]}
                            >
                                <Input
                                    placeholder="Voucher No:"
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="note"
                                label="Note:"
                                className="title-Text"
                                rules={[
                                    { required: false, message: "Please input ledger name" },
                                ]}
                            >
                                <Input
                                    placeholder="Note:"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="ledgerv"
                                label="Ledger:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select ledger" },
                                ]}
                            >
                                <SelectLedgerCashEqui onChange={(val) => onchangecashequi(val)} selected={cashequi} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ marginTop: 30, height: 40 }} icon={<SaveOutlined />} >
                                    Save
                                </Button>
                            </Space>
                        </Col>
                        {trnid != null &&
                            <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                                <Space size="small" >
                                    <Button type="primary" style={{ marginTop: 30, height: 40 }} icon={<SaveOutlined />} onClick={() => {download(trnid); }} >
                                        Download Voucher
                                    </Button>
                                </Space>
                            </Col>
                        }
                    </Row>
                </Form>
            </Card>
        </Card>
    );
}
