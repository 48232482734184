import { FileExcelOutlined, FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Form, Row, Skeleton, Space } from 'antd';
import React, { CSSProperties, useEffect } from 'react';
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import { Column } from '@ant-design/plots';

export default function InstituteCountStat() {

    const fetchinstituteCount = useStoreActions((state) => state.customerSupport.fetchinstituteCount);
    const instituteCount = useStoreState((state) => state.customerSupport.instituteCount);
    const loading = useStoreState((state) => state.customerSupport.loading);

    useEffect(() => {
        fetchinstituteCount();
    }, []);

    const styles: { [key: string]: CSSProperties } = {
        dashboard: {
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', // Centering the main content
        },
        box: {
          backgroundColor: '#00a36d',
          color: 'white',
          borderRadius: '8px',
          padding: '20px',
          width: '300px',
          margin: '10px',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease',
        },
        totalInstitute: {
          backgroundColor: '#00C987',
          marginBottom: '30px',
        },
        h2: {
          fontSize: '42px',
          marginBottom: '10px',
          fontWeight: 'bold',
        },
        h3: {
          fontSize: '20px',
          marginBottom: '15px',
        },
        p: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
        row: {
          display: 'flex',
          justifyContent: 'center', // Centering the boxes in the row
          flexWrap: 'wrap', // Allow wrapping of boxes if necessary
        },
        subRow: {
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '15px',
        },
        subBox: {
          width: '90px',
          textAlign: 'center',
          backgroundColor: 'white',
          color: '#00a36d',
          borderRadius: '8px',
          padding: '10px',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
          marginLeft:'5px'
        },
        subBoxH4: {
          fontSize: '16px',
          marginBottom: '5px',
        },
        subBoxP: {
          fontSize: '20px',
        },
      };
    

    return (
        <>
            <Card title="Institute Count">
                <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                {instituteCount!=null  && (
                    <div style={styles.dashboard}>
                        <div style={{ ...styles.box, ...styles.totalInstitute }}>
                            <h3 style={styles.h3}>Total Institute</h3>
                            <h2 style={styles.h2}>{instituteCount?.totalInstitute}</h2>
                        </div>

                        <div style={styles.row}>
                            <div style={styles.box}>
                                <h3 style={styles.h3}>Total Active</h3>
                                <h2 style={styles.h2}>{instituteCount?.totalActive}</h2>
                                <div style={styles.subRow}>
                                    <div style={styles.subBox}>
                                        <h4 style={styles.subBoxH4}>SPG</h4>
                                        <p style={styles.subBoxP}>{instituteCount?.totalActiveSpg}</p>
                                    </div>
                                    <div style={styles.subBox}>
                                        <h4 style={styles.subBoxH4}>Premium</h4>
                                        <p style={styles.subBoxP}>{instituteCount?.totalActivePremium}</p>
                                    </div>
                                    <div style={styles.subBox}>
                                        <h4 style={styles.subBoxH4}>Basic</h4>
                                        <p style={styles.subBoxP}>{instituteCount?.totalActiveBasic}</p>
                                    </div>
                                </div>
                            </div>

                            <div style={styles.box}>
                                <h3 style={styles.h3}>Total Pending</h3>
                                <h2 style={styles.h2}>{instituteCount?.totalPending}</h2>
                                <div style={styles.subRow}>
                                    <div style={styles.subBox}>
                                        <h4 style={styles.subBoxH4}>SPG</h4>
                                        <p style={styles.subBoxP}>{instituteCount?.totalPendingSpg}</p>
                                    </div>
                                    <div style={styles.subBox}>
                                        <h4 style={styles.subBoxH4}>Premium</h4>
                                        <p style={styles.subBoxP}>{instituteCount?.totalPendingPremium}</p>
                                    </div>                                    
                                    <div style={styles.subBox}>
                                        <h4 style={styles.subBoxH4}>Basic</h4>
                                        <p style={styles.subBoxP}>{instituteCount?.totalPendingBasic}</p>
                                    </div>
                                </div>
                            </div>

                            <div style={styles.box}>
                                <h3 style={styles.h3}>Total Inactive</h3>
                                <h2 style={styles.h2}>{instituteCount?.totalInactive}</h2>
                                <div style={styles.subRow}>
                                    <div style={styles.subBox}>
                                        <h4 style={styles.subBoxH4}>SPG</h4>
                                        <p style={styles.subBoxP}>{instituteCount?.totalInactiveSpg}</p>
                                    </div>
                                    <div style={styles.subBox}>
                                        <h4 style={styles.subBoxH4}>Premium</h4>
                                        <p style={styles.subBoxP}>{instituteCount?.totalInactivePremium}</p>
                                    </div>
                                    <div style={styles.subBox}>
                                        <h4 style={styles.subBoxH4}>Basic</h4>
                                        <p style={styles.subBoxP}>{instituteCount?.totalInactiveBasic}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Card>
        </>
    );
}
