import { FileExcelOutlined, FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Form, Row, Skeleton, Space } from 'antd';
import React, { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import { Column } from '@ant-design/plots';
import TableView from '../../../contents/AntTableResponsive';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf, lpowerdbypdf, ldatepdf, pdfGenerate } from '../../utils/pdf';
require('jspdf-autotable');


export default function PersonWiseInstitute() {

    const fetchpartnerWiseInstituteCount = useStoreActions((state) => state.customerSupport.fetchpartnerWiseInstituteCount);
    const partnerWiseInstituteCount = useStoreState((state) => state.customerSupport.partnerWiseInstituteCount);
    const loading = useStoreState((state) => state.customerSupport.loading);

    useEffect(() => {
        fetchpartnerWiseInstituteCount();
    }, []);

    // Restructure data to flatten instituteList so each packageName becomes its own record
    const chartData = partnerWiseInstituteCount.flatMap(item =>
        item.instituteList.map(institute => ({
            partnerName: item.partnerName, // x-axis field
            packageName: institute.packageName, // Series field (e.g., "Premium", "SBL")
            totalInstitute: institute.totalInstitute, // y-axis field
        }))
    );

    const config: any = {
        data: chartData,
        isGroup: true, // Group bars by partnerName
        xField: 'partnerName', // Field for the x-axis
        yField: 'totalInstitute', // Field for the y-axis
        seriesField: 'packageName', // Distinguish bars by packageName
        label: {
            position: 'middle',
            layout: [
                { type: 'interval-adjust-position' },
                { type: 'interval-hide-overlap' },
                { type: 'adjust-color' },
            ],
        },
        scrollbar: {
            type: 'horizontal', // Enable horizontal scroll
        },
        slider: {
            start: 0,
            end: 1, // Adjust to control the visible range
        },
    };

    const columns = [
        { title: 'Name', dataIndex: 'partnerName', key: "partnerName", showOnResponse: true, showOnDesktop: true },
        { title: 'Total Institute', dataIndex: 'totalInstitute', key: "totalInstitute", showOnResponse: true, showOnDesktop: true },
        { title: 'Basic', dataIndex: 'totalBasic', key: "totalBasic", showOnResponse: true, showOnDesktop: true },
        { title: 'SBL', dataIndex: 'totalSbl', key: "totalSbl", showOnResponse: true, showOnDesktop: true },
        { title: 'Premium', dataIndex: 'totalPremium', key: "totalPremium", showOnResponse: true, showOnDesktop: true },
    ]

    function exportPdf() {

        var doc = new jsPDF("p", "mm", "a4");

        // var details = `Exam Fourth Subject Assigned List of ${$(".sectionSelect").text()}`;
        var details = `Partner Wise Institute List`;
        pdfDataL(doc, "");
        doc.text(details, 105, 40, 'center');
        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = ppowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + pdatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };


        var col = [
            "Name",
            "Total Institute",
            "Basic",
            "SBL",
            "Premium",
        ].filter(Boolean);

        var rows: any = [];


        partnerWiseInstituteCount?.forEach(element => {
            var temp: any = [
                element.partnerName,
                element.totalInstitute,
                element.totalBasic,
                element.totalSbl,
                element.totalPremium,
            ];
            rows.push(temp);
        });

        doc.autoTable(col, rows, {
            startY: 45,
            showHeader: "firstPage",
            theme: 'grid',

            headerStyles: {
                // fillColor: [105, 105, 105],
                // textColor: [255, 255, 255],
                // fontSize: 10
                lineWidth: .01,
                lineColor: [224, 224, 224]
            },
            styles: {
                overflow: 'linebreak',
            },

            addPageContent: pageContent,

        });


        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        doc.setPage(1 + doc.internal.getCurrentPageInfo().pageNumber - doc.autoTable.previous.pageCount);
        doc.save(details + ".pdf");

    }

    return (
        <>
            <Card title="Person Wise Institute">
                <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                {partnerWiseInstituteCount?.length > 0 && (
                    <div style={{ overflowX: 'auto', paddingRight: 10 }}>
                        {/* Render the chart with the provided configuration */}
                        <Column {...config} /> <br />
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns,
                                dataSource: partnerWiseInstituteCount,
                                filterData: partnerWiseInstituteCount,
                                pagination: true,
                                bordered: true,
                                rowKey: "partnerId",
                            }}
                            mobileBreakPoint={768}
                        />
                        <Space style={{ float: 'right' }} size="middle">

                            <Button type="primary" icon={<FilePdfOutlined />} onClick={exportPdf} > Download PDF </Button>
                        </Space>
                    </div>
                )}
            </Card>
        </>
    );
}
