import { FileExcelOutlined, FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Form, Row, Skeleton, Space } from 'antd';
import React from 'react';
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import moment from 'moment';
import { Column } from '@ant-design/plots';
import TableView from '../../../contents/AntTableResponsive';

export default function InstituteCreateList() {

    const fetchinstituteCreateList = useStoreActions((state) => state.customerSupport.fetchinstituteCreateList);
    const instituteCreateList = useStoreState((state) => state.customerSupport.instituteCreateList);
    const loading = useStoreState((state) => state.customerSupport.loading);

    const callApiFunction = (value) => {
        let postData = {
            fromDate: moment(value?.fromDate).format("YYYY-MM-DD"),
            toDate: moment(value?.toDate).format("YYYY-MM-DD"),
        };
        fetchinstituteCreateList(postData);
    };

    const columns = [
        { title: 'Institute ID', dataIndex: 'instituteId', key: "instituteId", showOnResponse: true, showOnDesktop: true  },
        { title: 'Institute Name', dataIndex: 'instituteName', key: "instituteName", showOnResponse: true, showOnDesktop: true  },
        { title: 'PIP', dataIndex: 'partnerName', key: "partnerName", showOnResponse: true, showOnDesktop: true  },
        { title: 'Create Date', dataIndex: 'createDate', key: "createDate", showOnResponse: true, showOnDesktop: true  },
        { title: 'Status', dataIndex: 'instituteStatus', key: "firstTransactionDate", showOnResponse: true, showOnDesktop: true  },    ]
    return (
        <>
            <Card title="Person Wise Bill Collection">
                <Form
                    layout="vertical"
                    onFinish={callApiFunction}
                    id="searchReport"
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="fromDate"
                                label="From Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter from date" },
                                ]}
                            >
                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="toDate"
                                label="To Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter to date" },
                                ]}
                            >
                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small">
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                    Search
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>
                    </Row>
                </Form>
                <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                {instituteCreateList?.length > 0 &&
                    <div className="datatable-responsive-demo">
                    <TableView
                        antTableProps={{
                            showHeader: true,
                            columns,
                            dataSource: instituteCreateList,
                            filterData: instituteCreateList,
                            pagination: true,
                            bordered: true,
                            rowKey: "instituteId",
                        }}
                        mobileBreakPoint={768}
                    />
                </div>
                }
            </Card>
        </>
    );
}
