import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, Descriptions, DatePicker } from 'antd'
import { AimOutlined, DeleteOutlined, EditOutlined, EyeOutlined, MoneyCollectOutlined, PrinterOutlined, SaveOutlined, SearchOutlined, SettingOutlined, UngroupOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import TableView from '../../../../contents/AntTableResponsive';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import { moneyFormat, inWords } from '../../../../utils/utils';
import { SelectLedgerPayment } from '../../../select/SelectLedgerPayment';
import { SelectLedgerCashEqui } from '../../../select/SelectLedgerCashEqui';

const { Option } = Select;

const d = new Date();
const year = d.getFullYear();


// Print Media
const ComponentToPrint = React.forwardRef((props: any, ref: any) => {
    const { details, instituteInfo } = props;

    let len = 0;

    const filterAddition = details?.additionList?.filter(item => item.amount !== 0);
    const filterDeduction = details?.deductionList?.filter(item => item.amount !== 0);
    
    let additionsLen = filterAddition?.length;
    let deductionslen = filterDeduction?.length;
  
    if (additionsLen > deductionslen) {
        len = additionsLen;
    } else {
        len = deductionslen;
    }
    let earDed = new Array(len).fill('');

    const totalAddition = details?.additionList?.reduce((sum, item) => sum + item.amount, 0);
    const totalDeduction = details?.deductionList?.reduce((sum, item) => sum + item.amount, 0);

    return (
        <div className='print-sourcex' ref={ref} style={{ fontSize: 11, padding: 40 }} >
            <br /> <br />
            <div>
                <div style={{ textAlign: 'center' }}>
                    <span style={{ fontWeight: 'bold', fontSize: 14 }}>{instituteInfo?.instituteName}</span> <br />
                    <span> {instituteInfo?.address}</span>    <br /> <br />
                    <span style={{ fontWeight: 'bold', fontSize: 14 }}>Salary Slip</span> <br />
                    <span> For the month of: {details?.salaryMonth}, {details?.salaryYear}</span>
                </div>
            </div>
            <br />
            <table style={{ width: "100%" }} className="paysalary1">
                <tbody>
                    <tr style={{ border: '1px solid', paddingLeft: 2 }}>
                        <td style={{ width: "25%" }}>Employee Name</td>
                        <td style={{ width: "25%", textAlign: 'center', }}>{details?.staffName}</td>
                        <td style={{ width: "25%" }}>Date of Joining</td>
                        <td style={{ textAlign: 'center', width: "25%" }} >{details?.joiningDate}</td>
                    </tr>
                    <tr style={{ border: '1px solid', paddingLeft: 2 }}>
                        <td style={{ width: "25%" }}>Employee Code</td>
                        <td style={{ width: "25%", textAlign: 'center', }} >{details?.customStaffId}</td>
                        <td style={{ width: "25%" }} >Salary Month-Working Days</td>
                        <td style={{ textAlign: 'center', width: "25%" }}>{details?.salaryMonthWorkingDays === 0 ? null : details?.salaryMonthWorkingDays}</td>
                    </tr>
                    <tr style={{ border: '1px solid', paddingLeft: 2 }}>
                        <td style={{ width: "25%" }}>Designation</td>
                        <td style={{ width: "25%", textAlign: 'center', }}>{details?.designation}</td>
                        <td style={{ width: "25%" }}>Salary Month-Days Attended</td>
                        <td style={{ textAlign: 'center', width: "25%" }}>{details?.salaryMonthAttended === 0 ? null : details?.salaryMonthAttended}</td>
                    </tr>
                </tbody>
            </table>
            <br />
            <div style={{ display: 'flex' }}>
                <table style={{ width: "50%" }} className="paysalary">
                    <tbody>
                        <tr style={{ border: '1px solid' }}>
                            <th colSpan={2}>Earnings</th>
                        </tr>

                        {earDed.map((item, index) => (

                            (filterAddition?.[index] !== undefined ) ? <tr style={{ border: '1px solid'}} >
                                <td style={{ paddingLeft: 2, width: "50%" }}>{moneyFormat(filterAddition[index].headName)}</td>
                                <td style={{ textAlign: "right", width: "50%" }}>{moneyFormat(filterAddition[index].amount)}</td>
                            </tr> : <tr style={{ border: '1px solid'}}>
                                <td style={{ color: "#fff", width: "50%" }}>text</td>
                                <td style={{ color: "#fff", width: "50%" }}>text</td>
                            </tr>

                        ))}
                        <tr style={{ fontWeight: 'bold' , border: '1px solid'}}>
                            <td style={{ paddingLeft: 2, width: "50%" }}>Total</td>
                            <td style={{ textAlign: "right", width: "50%" }} >{moneyFormat(totalAddition)}</td>
                        </tr>
                    </tbody>
                </table>
                <table style={{ width: "50%" }} className="paysalary1">
                    <tbody>
                        <tr style={{ border: '1px solid', borderLeft: "none" }}>
                            <th colSpan={2}>Deductions</th>
                        </tr>

                        {earDed.map((item, index) => (

                            filterDeduction?.[index] !== undefined  ? <tr style={{ border: '1px solid', borderLeft:"0px"}}>
                                <td style={{ paddingLeft: 2, width: "50%" }}>{moneyFormat(filterDeduction[index].headName)}</td>
                                <td style={{ textAlign: "right", width: "50%" }}>{moneyFormat(filterDeduction[index].amount)}</td>
                            </tr> : <tr style={{ border: '1px solid', borderLeft:"0px"}}>
                                <td style={{ color: "#fff", width: "50%" }}>text</td>
                                <td style={{ color: "#fff", width: "50%" }}>text</td>
                            </tr>

                        ))}
                        <tr style={{ fontWeight: 'bold', border: '1px solid', borderLeft:"0px" }}>
                            <td style={{ paddingLeft: 2, width: "50%" }}>Total</td>
                            <td style={{ textAlign: "right", width: "50%" }} >{moneyFormat(totalDeduction)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <table style={{ width: "100%" }} className="paysalary1">
                <tbody>
                    <tr style={{ border: '1px solid', borderTop: "none", fontWeight: 'bold', background: "#ddd" }}>
                        <th>Net Salary: {moneyFormat(details?.netSalary)}</th>
                    </tr>
                </tbody>
            </table>
            <br />
            <table style={{ width: "100%", textAlign: 'center' }} className="paysalary1">
                <tbody>
                    <tr style={{ fontWeight: 'bold', }}>
                        <th colSpan={4}>Payment Information</th>
                    </tr>
                    <tr style={{ border: '1px solid', paddingLeft: 2 }}>
                        <td style={{ width: "20%", fontWeight: 'bold' }}>Payment Type</td>
                        <td style={{ width: "20%", fontWeight: 'bold' }}>Date</td>
                        <td style={{ width: "20%", fontWeight: 'bold' }}>Name of Bank</td>
                        <td style={{ width: "20%", fontWeight: 'bold' }}>Account No.</td>
                        <td style={{ width: "20%", fontWeight: 'bold' }}>Branch Name</td>
                    </tr>
                    <tr style={{ border: '1px solid', paddingLeft: 2 }}>
                        <td style={{ width: "20%" }}>{details?.paymentType} </td>
                        <td style={{ width: "20%" }}>{details?.paymentDate} </td>
                        <td style={{ width: "20%" }}>{details?.bankName} </td>
                        <td style={{ width: "20%" }}>{details?.accountNo} </td>
                        <td style={{ width: "20%" }}>{details?.branchName} </td>

                    </tr>
                </tbody>
            </table>
            <div style={{ marginTop: 80, display: "flex", justifyContent: "space-evenly" }}>
                <strong>Prepared by</strong>
                <strong>Checked by</strong>
                <strong>Recommended by</strong>
                <strong>Approved by</strong>
            </div>

        </div>
    );
});
// Print Media
const ComponentToPrint2 = React.forwardRef((props: any, componentRefSecond: any) => {
    const { bankAdviseListView, year, month, salarySheetList } = props;

    let totalnetsalary = salarySheetList?.reduce(function (acc, obj) { return acc + obj.netSalary; }, 0);

    return (
        <div className='print-source' ref={componentRefSecond} >
            <blockquote className="rich-text-component css-1x02zh0 eu4oa1w0" style={{ padding: 20, height: 842 }}>
                <br /> <br /> <br />
                <p className="rich-text-component css-ex6hny e1wnkr790">
                    To<br />
                    Manager<br />
                    {bankAdviseListView?.bankName}<br />
                    {bankAdviseListView?.branchName}<br />
                    {bankAdviseListView?.address}<br /></p>
                <p className="rich-text-component css-ex6hny e1wnkr790"><strong>Subject: {bankAdviseListView?.subjectContent} {month}, {year} </strong> </p>
                <p className="rich-text-component css-ex6hny e1wnkr790">
                    {bankAdviseListView?.salutation}, <br />
                    Please make the payroll transfer from the above Bank Account Number to the below mentioned account number towards employees salaries:
                </p>

                <table style={{ width: "100%", fontSize: '11px', border:"1px #000 solid" }} className="bankAdviseLetter">
                    <tr style={{ background: "#ddd", textAlign: "center", }}>
                        <th style={{ width: '4%' }}>SL</th>
                        <th style={{ width: '18%' }}>Employee's Name</th>
                        <th style={{ width: '10%' }}>ID</th>
                        <th style={{ width: '15%' }}>Designation</th>
                        <th style={{ width: '14%' }}>Bank Name</th>
                        <th style={{ width: '15%' }}>Branch Name</th>
                        <th style={{ width: '14%' }}>Bank Account No</th>
                        <th style={{ width: '10%' }}>Net Pay</th>
                    </tr>
                    {salarySheetList?.map((item, index) => (
                        <tr key={index}>
                            <td style={{ textAlign: "center" }}>{index + 1}</td>
                            <td>{item.staffName}</td>
                            <td style={{ textAlign: "center" }}>{item.customStaffId}</td>
                            <td style={{ textAlign: "center" }}>{item.designation}</td>
                            <td style={{ textAlign: "center" }}>{item.bankName}</td>
                            <td style={{ textAlign: "center" }}>{item.branchName}</td>
                            <td style={{ textAlign: "center" }}>{item.accountNo}</td>
                            <td style={{ textAlign: "right" }}>{moneyFormat(item.netSalary)}</td>
                        </tr>
                    ))}
                    <tfoot>
                        <tr>
                            <td className="printText" colSpan={7}><strong>Total Net Payable</strong></td>
                            <td className="printText" style={{ textAlign: "right" }}>  {moneyFormat(totalnetsalary)}  </td>
                        </tr>
                        <tr>
                            <td className="printText" colSpan={8}><strong>Amount (In Words):</strong><span style={{textTransform:"capitalize"}}> {inWords(totalnetsalary)} </span></td>
                        </tr>
                    </tfoot>
                </table>
                <br />
                <span className="printText"> Kindly acknowledge the payment Advise as per the above information. Your cooperation will be highly appreciated.</span>
                <br />
                <span className="printText">Authorised Signature</span>:
                <br />
                <table style={{ width: "100%", fontSize: '11px', textAlign: "center" }} className="bankAdviseLetter">
                    <tr style={{ color: "#fff", height: 40 }}>
                        <td>1</td>
                        <td>2</td>
                        <td>3</td>
                    </tr>
                    <tr>
                        <td>{bankAdviseListView?.signatureContent1}</td>
                        <td>{bankAdviseListView?.signatureContent2}</td>
                        <td>{bankAdviseListView?.signatureContent3}</td>
                    </tr>
                </table>
            </blockquote>


        </div>
    );
});
// Print Media

export default function SalaryPayment() {
    const salarySheetList = useStoreState((state) => state.payroll.salarySheetList);
    const fetchsalarySheetList = useStoreActions((state) => state.payroll.fetchsalarySheetList);
    const paymentSalarySheet = useStoreActions((state) => state.payroll.paymentSalarySheet);
    const updateSalarySheet = useStoreActions((state) => state.payroll.updateSalarySheet);
    const deleteSalarySheet = useStoreActions((state) => state.payroll.deleteSalarySheet);
    const instituteInfo = useStoreState(state => state.auth.instituteInfo);
    const bankAdviseContentView = useStoreState(state => state.payroll.bankAdviseContentView)
    const fetchbankAdviseContentView = useStoreActions((state) => state.payroll.fetchbankAdviseContentView);
    // const viewForSalaryPayment = useStoreState((state) => state.payroll.viewForSalaryPayment);
    // const fetchviewForSalaryPayment = useStoreActions((state) => state.payroll.fetchviewForSalaryPayment);
    // const payEmployeeSalary = useStoreActions((state) => state.payroll.payEmployeeSalary);
    // const batchPayEmployeeSalary = useStoreActions((state) => state.payroll.batchPayEmployeeSalary);
    useEffect(() => {
        fetchbankAdviseContentView();
    }, [])


    /////////////

    const [additionList, setadditionList] = useState<any>([]);
    const [deductionList, setdeductionList] = useState<any>([]);
    const columns: any = [
        {
            title: 'Id',
            dataIndex: 'customStaffId',
            key: 'customStaffId',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Name',
            dataIndex: 'staffName',
            key: 'staffName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Designation',
            dataIndex: 'designation',
            key: 'designation',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Salary Year',
            dataIndex: 'salaryYear',
            key: 'salaryYear',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Salary Month',
            dataIndex: 'salaryMonth',
            key: 'salaryMonth',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Gross Salary',
            dataIndex: 'grossSalary',
            key: 'grossSalary',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                moneyFormat(record.grossSalary)
            )
        },
        {
            title: 'Deduction Amount',
            dataIndex: 'deductionAmount',
            key: 'deductionAmount',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                moneyFormat(record.deductionAmount)
            )
        },
        {
            title: 'Net Salary',
            dataIndex: 'netSalary',
            key: 'netSalary',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                moneyFormat(record.netSalary)
            )
        },
        {
            title: 'Payment Date',
            dataIndex: 'paymentDate',
            key: 'paymentDate',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Payment Status',
            dataIndex: 'paidStatus',
            key: 'paidStatus',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Update', dataIndex: '', key: '', showOnResponse: true, showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Update">
                        <Button type='primary'
                            onClick={() => {
                                setSheetData(record)
                                setIsModalVisible(true);
                                setadditionList(record?.additionList);
                                setdeductionList(record?.deductionList);
                            }}
                            icon={<EditOutlined />}

                        />
                    </Tooltip>


                </Space>
            ),
        },
        {
            title: 'Payment', dataIndex: '', key: '', showOnResponse: true, showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Payment">
                        <Button type='primary'
                            onClick={() => {
                                setSheetData(record)
                                setIsModalVisible2(true);
                            }}
                            icon={<MoneyCollectOutlined />}

                        />
                    </Tooltip>


                </Space>
            ),
        },
        {
            title: 'Delete', dataIndex: '', key: '', showOnResponse: true, showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Delete">
                        <Button danger
                            onClick={() => {
                                deleteSalarySheet(record?.sheetId)
                                setTimeout(() => {
                                    form.submit();
                                }, 1000);
                            }}
                            icon={<DeleteOutlined />}

                        />
                    </Tooltip>


                </Space>
            ),
        },
        {
            title: 'Salary Slip', dataIndex: '', key: '', showOnResponse: true, showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Salary Slip">
                        <Button type='primary'
                            onClick={() => {
                                setSheetData(record)
                                setIsModalVisible3(true);
                            }}
                            icon={<AimOutlined />}

                        />
                    </Tooltip>


                </Space>
            ),
        },


    ];


    const additionListCol: any = [
        {
            title: 'Head',
            dataIndex: 'headName',
            key: 'headName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => (
                <Input
                    placeholder="Amount"
                    value={record.amount}
                    className="amount"
                    onChange={onchangeValueAddition("amount", record, index)}
                />
            ),
        },
    ]
    const deductionListCol: any = [
        {
            title: 'Head',
            dataIndex: 'headName',
            key: 'headName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => (
                <Input
                    placeholder="Amount"
                    value={record.amount}
                    className="amount"
                    onChange={onchangeValueDeduction("amount", record, index)}
                />
            ),
        },
    ]
    const [sheetData, setSheetData] = useState<any>('');


    const onchangeValueAddition: any =
        useCallback((key, data, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const newData = [...additionList];
            newData[index][key] = e.target.value;
            setadditionList(newData);
        }, [additionList]);

    const onchangeValueDeduction: any =
        useCallback((key, data, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const newData = [...deductionList];
            newData[index][key] = e.target.value;
            setdeductionList(newData);
        }, [deductionList]);

    const updateData = () => {
        let payload = {
            "additionList": additionList,
            "deductionList": deductionList,
            "sheetId": sheetData?.sheetId
        }
        updateSalarySheet(payload);
        setIsModalVisible(false);
    };

    const [form] = Form.useForm();
    const [form2] = Form.useForm();

    const [dta, setDta] = useState<any>('');

    const onProcess = (value) => {
        setDta(value);
        fetchsalarySheetList(value);

    }

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisible2, setIsModalVisible2] = useState(false);
    const [isModalVisible3, setIsModalVisible3] = useState(false);

    const submitForm = (value) => {
        // value.sheetId = sheetId;
        // value.paymentDate = moment(value?.paymentDate).format("YYYY-MM-DD"),
        //     // payEmployeeSalary(value);
        // paymentform.resetFields();
        // setIsModalVisible(false);
    }

    const [paymentType, setPaymentType] = useState<any>("");
    const [paymentNo, setpaymentNo] = useState<any>(null);

    const componentRef: any = useRef();
    const componentRefSecond: any = useRef();

    const paymentUpdate = (value) => {
        let finalPayload = {
            "sheetId": sheetData?.sheetId,
            "paymentDate": moment(value.paymentDate).format('YYYY-MM-DD'),
            "paymentType": value.paymentType,
            "paymentLedgerId": value.paymentLedgerId,
        }
        paymentSalarySheet(finalPayload);
    };

    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
        // console.log(value)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    }


    return (
        <Card title="Salary Payment / Bank Advise Content / Slip">
            <Form
                layout="vertical"
                id="sForm"
                form={form}
                onFinish={onProcess}
            >
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>  </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item
                            name="salaryYear"
                            label="Salary Year"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select year" },
                            ]}
                        >
                            <Select placeholder="Select year">
                                <Option key={year - 1} value={year - 1}>{year - 1}</Option>
                                <Option key={year} value={year}>{year}</Option>
                                <Option key={year + 1} value={year + 1}>{year + 1}</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item
                            name="salaryMonth"
                            label="Salary Month"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select month" },
                            ]}
                        >
                            <Select placeholder="Select month">
                                <Option key="1" value="January">January</Option>
                                <Option key="2" value="February">February</Option>
                                <Option key="3" value="March">March</Option>
                                <Option key="4" value="April">April</Option>
                                <Option key="5" value="May">May</Option>
                                <Option key="6" value="June">June</Option>
                                <Option key="7" value="July">July</Option>
                                <Option key="8" value="August">August</Option>
                                <Option key="9" value="September">September</Option>
                                <Option key="10" value="October">October</Option>
                                <Option key="11" value="November">November</Option>
                                <Option key="12" value="December">December</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        <Space size={'middle'} >
                            <Button type='primary' htmlType='submit' icon={<SearchOutlined />}> Search</Button>
                        </Space>
                    </Col>

                </Row>
                <Row className="m-t-mo-30">
                    <Col span="24">
                        <div className="datatable-responsive-demo">
                            {salarySheetList?.length > 0 &&
                                <>
                                    <TableView
                                        antTableProps={{
                                            showHeader: true,
                                            columns,
                                            dataSource: salarySheetList,
                                            filterData: salarySheetList,
                                            pagination: false,
                                            bordered: true,
                                            rowKey: "sheetId",
                                            rowSelection: rowSelection
                                        }}
                                        mobileBreakPoint={768}
                                    />
                                    <Space size={'middle'} style={{ float: "right" }} >
                                        {bankAdviseContentView !== null && 
                                            <>
                                                <ReactToPrint
                                                    trigger={() => <Button disabled={selectedValue?.length==0?true:false} type={selectedValue?.length==0?"default":'primary'} icon={<PrinterOutlined />}>Print Bank Advise Content</Button>}
                                                    content={() => componentRefSecond.current}
                                                />
                                                <ComponentToPrint2 ref={componentRefSecond} bankAdviseListView={bankAdviseContentView} month={form.getFieldValue("salaryMonth")} year={form.getFieldValue("salaryYear")} salarySheetList={selectedValue} />
                                            </>
                                        }
                                    </Space>

                                </>
                            }
                        </div>
                    </Col>
                </Row>
            </Form>

            <Modal
                title="Update"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => { setIsModalVisible(false); }}
                cancelText="Close"
                okText="Save"
                centered
                width={'50%'}
                destroyOnClose
                footer={null}

            >

                <Descriptions
                    // title="User Info"
                    bordered
                    column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
                >
                    <Descriptions.Item label="Staff Id" >
                        <div>{sheetData?.customStaffId}</div>
                    </Descriptions.Item>
                    <Descriptions.Item label="Salary Year" >
                        <div>{sheetData?.salaryYear}</div>
                    </Descriptions.Item>
                    <Descriptions.Item label="Staff Name" >
                        <div>{sheetData?.staffName}</div>
                    </Descriptions.Item>
                    <Descriptions.Item label="Salary Month" >
                        <div>{sheetData?.salaryMonth}</div>
                    </Descriptions.Item>
                    <Descriptions.Item label="Designation" >
                        <div>{sheetData?.designation}</div>
                    </Descriptions.Item>
                    <Descriptions.Item label="Gross Salary" >
                        <div>{sheetData?.grossSalary}</div>
                    </Descriptions.Item>
                    <Descriptions.Item label="Deduction Amount" >
                        <div>{sheetData?.deductionAmount}</div>
                    </Descriptions.Item>
                    <Descriptions.Item label="Net Salary" >
                        <div>{sheetData?.netSalary}</div>
                    </Descriptions.Item>

                </Descriptions>
                <br />
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns: additionListCol,
                                dataSource: additionList,
                                filterData: additionList,
                                pagination: false,
                                bordered: true,
                                rowKey: "headId",
                            }}
                            mobileBreakPoint={768}
                        />
                    </Col>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns: deductionListCol,
                                dataSource: deductionList,
                                filterData: deductionList,
                                pagination: false,
                                bordered: true,
                                rowKey: "headId",
                            }}
                            mobileBreakPoint={768}
                        />
                    </Col>
                </Row>
                <Space size={'middle'} style={{ float: "right" }} >
                    <Button type='primary' icon={<SaveOutlined />} onClick={() => updateData()}>Save</Button>
                </Space>
            </Modal>
            <Modal
                title="Update"
                visible={isModalVisible2}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => { setIsModalVisible2(false); }}
                cancelText="Close"
                okText="Save"
                centered
                width={'50%'}
                destroyOnClose
                footer={null}
            >

                <Descriptions
                    // title="User Info"
                    bordered
                    column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
                >
                    <Descriptions.Item label="Staff Id" >
                        <div>{sheetData?.customStaffId}</div>
                    </Descriptions.Item>
                    <Descriptions.Item label="Salary Year" >
                        <div>{sheetData?.salaryYear}</div>
                    </Descriptions.Item>
                    <Descriptions.Item label="Staff Name" >
                        <div>{sheetData?.staffName}</div>
                    </Descriptions.Item>
                    <Descriptions.Item label="Salary Month" >
                        <div>{sheetData?.salaryMonth}</div>
                    </Descriptions.Item>
                    <Descriptions.Item label="Designation" >
                        <div>{sheetData?.designation}</div>
                    </Descriptions.Item>
                    <Descriptions.Item label="Gross Salary" >
                        <div>{sheetData?.grossSalary}</div>
                    </Descriptions.Item>
                    <Descriptions.Item label="Deduction Amount" >
                        <div>{sheetData?.deductionAmount}</div>
                    </Descriptions.Item>
                    <Descriptions.Item label="Net Salary" >
                        <div>{sheetData?.netSalary}</div>
                    </Descriptions.Item>
                </Descriptions>
                <Form
                    layout="vertical"
                    id="sFormSubmit"
                    form={form2}
                    onFinish={paymentUpdate}
                >
                    <Row>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item
                                name="paymentDate"
                                label="Payment Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select date" },
                                ]}
                            >
                                <DatePicker placeholder="Payment Date" style={{ width: '100%' }} format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item
                                name="paymentType"
                                label="Paymemt Type"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select payment type" },
                                ]}
                            >
                                <Select placeholder='Payment Type' value={paymentType} onChange={(e) => setPaymentType(e)} >
                                    <Select.Option value="Cheque">Cheque</Select.Option>
                                    <Select.Option value="Bank Advise">Bank Advise</Select.Option>
                                    <Select.Option value="Cash">Cash</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item
                                name="paymentLedgerId"
                                label="Paymemt Ledger"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select payment ledger" },
                                ]}
                            >
                                <SelectLedgerCashEqui />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Space size={'middle'} style={{ float: "right" }} >
                                <Button type='primary' htmlType='submit' icon={<SaveOutlined />}> Save</Button>
                            </Space>
                        </Col>
                    </Row>


                </Form>
            </Modal>
            <Modal
                title="Payment"
                visible={isModalVisible3}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible3(false)}
                cancelText="Close"
                okText="Save"
                centered
                width={'50%'}
                footer={null}
            >
                <ReactToPrint
                    trigger={() => <Button type='primary' icon={<PrinterOutlined />}>Print</Button>}
                    content={() => componentRef.current}
                />
                <ComponentToPrint ref={componentRef} details={sheetData} instituteInfo={instituteInfo} />
            </Modal>

        </Card>
    )

}