import { Button, Card, Col, Form, Modal, notification, Row, Select, Space } from 'antd';
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import { FileExcelOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons';
import TableView from '../../../contents/AntTableResponsive';
import { SelectClassConfig } from '../../select/SelectClassConfig';
import { useState } from 'react';


export default function BillingCreate() {

    const fetchinstituteListForBilling = useStoreActions((state) => state.customerSupport.fetchinstituteListForBilling);
    const createInstituteBilling = useStoreActions((state) => state.customerSupport.createInstituteBilling);
    const instituteListForBilling = useStoreState((state) => state.customerSupport.instituteListForBilling)
    const [myform2] = Form.useForm();
    const { Option } = Select;

    const sectionWiseAdmissionReportFormSubmit = (value) => {
        let postData: any = {
            "billCycle": value.billCycle,
            "billPayablePerson": value.billPayablePerson,
            "instituteOwner": "SDL"
        }
        fetchinstituteListForBilling(postData);
    }
    const column = [
        { title: 'Institute ID', dataIndex: 'instituteId', key: "instituteId", showOnResponse: true, showOnDesktop: true },
        { title: 'Institute Name', dataIndex: 'instituteName', key: "instituteName", showOnResponse: true, showOnDesktop: true },
        { title: 'Status', dataIndex: 'status', key: "status", showOnResponse: true, showOnDesktop: true },
        { title: 'Package', dataIndex: 'packageName', key: "packageName", showOnResponse: true, showOnDesktop: true },
        { title: 'Partner', dataIndex: 'partnerName', key: "partnerName", showOnResponse: true, showOnDesktop: true },
        { title: 'Type', dataIndex: 'instituteType', key: "instituteType", showOnResponse: true, showOnDesktop: true },
        { title: 'Bill Cycle', dataIndex: 'billCycle', key: "billCycle", showOnResponse: true, showOnDesktop: true },
        { title: 'Bill Type', dataIndex: 'billType', key: "billType", showOnResponse: true, showOnDesktop: true },
        { title: 'Bill Payable Person', dataIndex: 'billPayablePerson', key: "billPayablePerson", showOnResponse: true, showOnDesktop: true },
        { title: 'Bill Rate', dataIndex: 'billRate', key: "billRate", showOnResponse: true, showOnDesktop: true },
        { title: 'Total Student', dataIndex: 'totalStudent', key: "totalStudent", showOnResponse: true, showOnDesktop: true },
        { title: 'Minimum Student', dataIndex: 'minimumStudent', key: "minimumStudent", showOnResponse: true, showOnDesktop: true },
    ];


    const [isModalVisible, setIsModalVisible] = useState(false);
    const [instituteId, setinstituteId] = useState<any>(null);
    const [updateform] = Form.useForm();

    let year = new Date().getFullYear();

    const updateInfoForm = (value) => {
        value.instituteIds = selectedValue.map(item => item.instituteId);
        value.billingMonth=  value.billingMonth  === 'xx' ?'':value.billingMonth
        createInstituteBilling(value);
        setselectedRowKeys([]);
        setselectedValue([]);
        updateform.resetFields();
        setIsModalVisible(false)
    }

    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
        // console.log(value)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const openModal = () => {

        if (selectedValue.length === 0) {
            notification.error({ message: "Please select institute" });
            return;
        }

        setIsModalVisible(true)
    }

    return (
        <>
            <Card title="Create Institute Billing">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 4 }} xl={{ span: 20, offset: 4 }}>
                        <Form
                            layout="vertical"
                            id="instructionForm"
                            onFinish={sectionWiseAdmissionReportFormSubmit}
                            form={myform2}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="billCycle"
                                        label="Bill Cycle"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please Select" },
                                        ]}
                                    >
                                        <Select placeholder="Bill Cycle">
                                            <Option key={0} value="MONTHLY">Monthly</Option>
                                            <Option key={1} value="HALF_YEARLY">Half Yearly</Option>
                                            <Option key={2} value="YEARLY">Yearly</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="billPayablePerson"
                                        label="Bill Payable Person"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please Select" },
                                        ]}
                                    >
                                        <Select placeholder="Bill Payable Person">
                                            <Option key={0} value="INSTITUTE">Institute</Option>
                                            <Option key={1} value="STUDENT">Student</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                    <Space size="small" >
                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <br />
                {instituteListForBilling !== null &&
                    <Row className="m-t-mo-30">
                        <Col span="24">
                            <div className="datatable-responsive-demo">
                                <TableView
                                    antTableProps={{
                                        showHeader: true,
                                        columns: column,
                                        dataSource: instituteListForBilling,
                                        filterData: instituteListForBilling,
                                        pagination: true,
                                        bordered: true,
                                        rowKey: "instituteId",
                                        rowSelection: rowSelection,
                                    }}
                                    mobileBreakPoint={768}
                                />
                            </div>
                            <br />
                            <Space size="middle" style={{ float: 'right' }}>
                                <Button type="primary" icon={<SettingOutlined />} onClick={() => openModal()}> Process</Button>
                            </Space>
                        </Col>

                    </Row>
                }
            </Card>

            <Modal
                title="Create"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Save"
                centered
            >
                <Form
                    layout="vertical"
                    onFinish={updateInfoForm}
                    id="update"
                    form={updateform}
                >
                    <Form.Item
                        name="billingYear"
                        label="Year:"
                        className="title-Text"
                        initialValue={year}
                        rules={[
                            { required: true, message: "Please select year" },
                        ]}
                        style={{ width: "100%" }}
                    >
                        <Select placeholder="Select Year" allowClear style={{ width: "100%" }}>
                            <Option value={year - 1}>{year - 1}</Option>
                            <Option value={year}>{year}</Option>
                            <Option value={year + 1}>{year + 1}</Option>
                        </Select>

                    </Form.Item>
                    <Form.Item
                        name="billingMonth"
                        label="Month:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please select month" },
                        ]}
                    >
                        <Select placeholder="Select Month" allowClear style={{ width: "100%" }}>
                            <Option value="">Not Applicable</Option>
                            <Option value="January-December">January-December</Option>
                            <Option value="January-June">January-June</Option>
                            <Option value="July-December">July-December</Option>
                            <Option value="January">January</Option>
                            <Option value="February">February</Option>
                            <Option value="March">March</Option>
                            <Option value="April">April</Option>
                            <Option value="May">May</Option>
                            <Option value="June">June</Option>
                            <Option value="July">July</Option>
                            <Option value="August">August</Option>
                            <Option value="September">September</Option>
                            <Option value="October">October</Option>
                            <Option value="November">November</Option>
                            <Option value="December">December</Option>
                        </Select>
                    </Form.Item>


                </Form>

            </Modal>
        </>
    )
}