import { notification } from 'antd';
import { Action, Thunk, thunk, action } from 'easy-peasy';
import { addSalaryHeadAddition, addSalaryHeadDeduction, deleteSalaryGrade, deleteSalaryHeadAddition, deleteSalaryHeadDeduction, deleteSalarySheet, fetchbankAdviseContentView, fetchsalaryGradeList, fetchsalaryHeadListAddition, fetchsalaryHeadListDeduction, fetchsalarySheetList, fetchsaveEmployeeSalaryConfigurationList, fetchviewSalaryDefaultConfiguration, paymentSalarySheet, saveEmployeeSalaryConfigurationByGrade, saveEmployeeSalaryConfigurationByManual, saveSalaryDefaultConfiguration, saveSalaryGrade, saveSalaryGradeConfiguration, updateSalaryGrade, updateSalaryHeadAddition, updateSalaryHeadDeduction, updateSalarySheet, updatebankAdviseContentView } from '../../../http/payroll/payroll';

export interface Payroll {


    //////
    salaryHeadListAddition: any;
    setsalaryHeadListAddition: Action<Payroll, any>;
    fetchsalaryHeadListAddition: Thunk<Payroll>;
    addSalaryHeadAddition: Thunk<Payroll, any>;
    updateSalaryHeadAddition: Thunk<Payroll, any>;
    deleteSalaryHeadAddition: Thunk<Payroll, any>;
    //////

    //////
    salaryHeadListDeduction: any;
    setsalaryHeadListDeduction: Action<Payroll, any>;
    fetchsalaryHeadListDeduction: Thunk<Payroll>;
    addSalaryHeadDeduction: Thunk<Payroll, any>;
    updateSalaryHeadDeduction: Thunk<Payroll, any>;
    deleteSalaryHeadDeduction: Thunk<Payroll, any>;
    //////

    //////
    salaryGradeList: any;
    setsalaryGradeList: Action<Payroll, any>;
    fetchsalaryGradeList: Thunk<Payroll>;
    saveSalaryGradeConfiguration: Thunk<Payroll, any>;
    saveSalaryGrade: Thunk<Payroll, any>;
    updateSalaryGrade: Thunk<Payroll, any>;
    deleteSalaryGrade: Thunk<Payroll, any>;
    //////


    //////
    saveEmployeeSalaryConfigurationList: any;
    setsaveEmployeeSalaryConfigurationList: Action<Payroll, any>;
    fetchsaveEmployeeSalaryConfigurationList: Thunk<Payroll>;
    saveEmployeeSalaryConfigurationByGrade: Thunk<Payroll, any>;
    saveEmployeeSalaryConfigurationByManual: Thunk<Payroll, any>;
    //////
   
    bankAdviseContentView: any;
    setbankAdviseContentView: Action<Payroll, any>;
    fetchbankAdviseContentView: Thunk<Payroll>;
    updatebankAdviseContentView: Thunk<Payroll, any>;    
    ///
    viewSalaryDefaultConfiguration: any;
    setviewSalaryDefaultConfiguration: Action<Payroll, any>;
    fetchviewSalaryDefaultConfiguration: Thunk<Payroll>;
    saveSalaryDefaultConfiguration: Thunk<Payroll, any>;

    salarySheetList: any;
    setsalarySheetList: Action<Payroll, any>;
    fetchsalarySheetList: Thunk<Payroll, any>;
    paymentSalarySheet: Thunk<Payroll, any>;
    updateSalarySheet: Thunk<Payroll, any>;
    deleteSalarySheet: Thunk<Payroll, any>;


}

export const payrollStore: Payroll = {
    salaryHeadListAddition: [],

    setsalaryHeadListAddition: action((state, payload) => {
        state.salaryHeadListAddition = payload;
    }),

    fetchsalaryHeadListAddition: thunk(async (actions) => {
        const response = await fetchsalaryHeadListAddition();
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body?.item?.length > 0) {
                actions.setsalaryHeadListAddition(body.item);
            } else {
                notification['warning']({
                    message: 'No data found',
                });
                actions.setsalaryHeadListAddition(body.item);
            }
        } else {
            const body = await response.json();
            notification['error']({
                message: 'Something went wrong',
            });
        }
    }),

    addSalaryHeadAddition: thunk(async (actions, payload) => {
        const response = await addSalaryHeadAddition(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchsalaryHeadListAddition();
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),

    updateSalaryHeadAddition: thunk(async (actions, payload) => {
        const response = await updateSalaryHeadAddition(payload);
        if (response.status === 201) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchsalaryHeadListAddition();
            } else {
                notification.error({ message: body.message })
            }

        } else {
            const body = await response.json();
            notification.error({ message: 'Something went wrong' })
        }
    }),

    deleteSalaryHeadAddition: thunk(async (actions, payload) => {
        const response = await deleteSalaryHeadAddition(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchsalaryHeadListAddition();
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: 'Something went wrong' })
        }
    }),

    salaryHeadListDeduction: [],

    setsalaryHeadListDeduction: action((state, payload) => {
        state.salaryHeadListDeduction = payload;
    }),

    fetchsalaryHeadListDeduction: thunk(async (actions) => {
        const response = await fetchsalaryHeadListDeduction();
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body?.item?.length > 0) {
                actions.setsalaryHeadListDeduction(body.item);
            } else {
                notification['warning']({
                    message: 'No data found',
                });
                actions.setsalaryHeadListDeduction(body.item);
            }
        } else {
            const body = await response.json();
            notification['error']({
                message: 'Something went wrong',
            });
        }
    }),

    addSalaryHeadDeduction: thunk(async (actions, payload) => {
        const response = await addSalaryHeadDeduction(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchsalaryHeadListDeduction();
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),

    updateSalaryHeadDeduction: thunk(async (actions, payload) => {
        const response = await updateSalaryHeadDeduction(payload);
        if (response.status === 201) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchsalaryHeadListDeduction();
            } else {
                notification.error({ message: body.message })
            }

        } else {
            const body = await response.json();
            notification.error({ message: 'Something went wrong' })
        }
    }),

    deleteSalaryHeadDeduction: thunk(async (actions, payload) => {
        const response = await deleteSalaryHeadDeduction(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchsalaryHeadListDeduction();
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: 'Something went wrong' })
        }
    }),
    salaryGradeList: [],

    setsalaryGradeList: action((state, payload) => {
        state.salaryGradeList = payload;
    }),

    fetchsalaryGradeList: thunk(async (actions) => {
        const response = await fetchsalaryGradeList();
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body?.item?.length > 0) {
                body.item.forEach((element, index) => {
                    element.key = index
                });
                // console.log(body.item)
                actions.setsalaryGradeList(body.item);
            } else {
                notification['warning']({
                    message: 'No data found',
                });
                actions.setsalaryGradeList(body.item);
            }
        } else {
            const body = await response.json();
            notification['error']({
                message: 'Something went wrong',
            });
        }
    }),    
    
    saveSalaryGradeConfiguration: thunk(async (actions, payload) => {
        const response = await saveSalaryGradeConfiguration(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchsalaryGradeList();
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification['error']({
                message: 'Something went wrong',
            });
        }
    }),

    saveSalaryGrade: thunk(async (actions, payload) => {
        const response = await saveSalaryGrade(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchsalaryGradeList();
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),

    updateSalaryGrade: thunk(async (actions, payload) => {
        const response = await updateSalaryGrade(payload);
        if (response.status === 201) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchsalaryGradeList();
            } else {
                notification.error({ message: body.message })
            }

        } else {
            const body = await response.json();
            notification.error({ message: 'Something went wrong' })
        }
    }),

    deleteSalaryGrade: thunk(async (actions, payload) => {
        const response = await deleteSalaryGrade(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchsalaryGradeList();
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: 'Something went wrong' })
        }
    }),


    saveEmployeeSalaryConfigurationList: [],

    setsaveEmployeeSalaryConfigurationList: action((state, payload) => {
        state.saveEmployeeSalaryConfigurationList = payload;
    }),

    fetchsaveEmployeeSalaryConfigurationList: thunk(async (actions) => {
        const response = await fetchsaveEmployeeSalaryConfigurationList();
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body?.item?.length > 0) {
                actions.setsaveEmployeeSalaryConfigurationList(body.item);
            } else {
                notification['warning']({
                    message: 'No data found',
                });
                actions.setsaveEmployeeSalaryConfigurationList(body.item);
            }
        } else {
            const body = await response.json();
            notification['error']({
                message: 'Something went wrong',
            });
        }
    }),    
    
    saveEmployeeSalaryConfigurationByGrade: thunk(async (actions, payload) => {
        const response = await saveEmployeeSalaryConfigurationByGrade(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchsaveEmployeeSalaryConfigurationList();
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification['error']({
                message: 'Something went wrong',
            });
        }
    }),

    saveEmployeeSalaryConfigurationByManual: thunk(async (actions, payload) => {
        const response = await saveEmployeeSalaryConfigurationByManual(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchsaveEmployeeSalaryConfigurationList();
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification['error']({
                message: 'Something went wrong',
            });
        }
    }),

    bankAdviseContentView: {},

    setbankAdviseContentView: action((state, payload) => {
        state.bankAdviseContentView = payload;
    }),

    fetchbankAdviseContentView: thunk(async (actions) => {
        const response = await fetchbankAdviseContentView();
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body?.messageType== 1) {
                actions.setbankAdviseContentView(body.item);
            } else {
                notification['warning']({
                    message: 'No data found',
                });
                actions.setbankAdviseContentView(body.item);
            }
        } else {
            notification['error']({
                message: 'Something went wrong',
            });
        }
    }),    
    
    updatebankAdviseContentView: thunk(async (actions, payload) => {
        const response = await updatebankAdviseContentView(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchsaveEmployeeSalaryConfigurationList();
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification['error']({
                message: 'Something went wrong',
            });
        }
    }),

    viewSalaryDefaultConfiguration: null,

    setviewSalaryDefaultConfiguration: action((state, payload) => {
        state.viewSalaryDefaultConfiguration = payload;
    }),

    fetchviewSalaryDefaultConfiguration: thunk(async (actions) => {
        const response = await fetchviewSalaryDefaultConfiguration();
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body?.messageType== 1) {
                actions.setviewSalaryDefaultConfiguration(body.item);
            } else {
                actions.setbankAdviseContentView(body.item);
            }
        } else {
            notification['error']({
                message: 'Something went wrong',
            });
        }
    }),    
    
    saveSalaryDefaultConfiguration: thunk(async (actions, payload) => {
        const response = await saveSalaryDefaultConfiguration(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification['error']({
                message: 'Something went wrong',
            });
        }
    }),

    salarySheetList: [],

    setsalarySheetList: action((state, payload) => {
        state.salarySheetList = payload;
    }),

    fetchsalarySheetList: thunk(async (actions, payload) => {
        const response = await fetchsalarySheetList(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body?.messageType== 1) {
                actions.setsalarySheetList(body.item);
            } else {
                notification['error']({
                    message: 'No Data Found',
                });
                actions.setsalarySheetList([]);
            }
        } else {
            notification['error']({
                message: 'Something went wrong',
            });
            actions.setsalarySheetList([]);
        }
    }),    
    
    deleteSalarySheet: thunk(async (actions, payload) => {
        const response = await deleteSalarySheet(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
            } else {
                notification.error({ message: body.message })
            }
        } else {
            notification['error']({
                message: 'Something went wrong',
            });
        }
    }),

    paymentSalarySheet: thunk(async (actions, payload) => {
        const response = await paymentSalarySheet(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
            } else {
                notification.error({ message: body.message })
            }
        } else {
            notification['error']({
                message: 'Something went wrong',
            });
        }
    }),

    updateSalarySheet: thunk(async (actions, payload) => {
        const response = await updateSalarySheet(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
            } else {
                notification.error({ message: body.message })
            }
        } else {
            notification['error']({
                message: 'Something went wrong',
            });
        }
    }),


}
