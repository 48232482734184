import { post, get, del} from "../http";




export const fetchsalaryHeadListAddition = () => get("/salary/addition/head/list");
export const addSalaryHeadAddition = (payload) => post("/salary/addition/head/save",payload);
export const updateSalaryHeadAddition = (payload) => post("/salary/addition/head/update",payload);
export const deleteSalaryHeadAddition = (payload) => del("/salary/addition/head/delete?additionId="+payload);

export const fetchsalaryHeadListDeduction = () => get("/salary/deduction/head/list");
export const addSalaryHeadDeduction = (payload) => post("/salary/deduction/head/save",payload);
export const updateSalaryHeadDeduction = (payload) => post("/salary/deduction/head/update",payload);
export const deleteSalaryHeadDeduction = (payload) => del("/salary/deduction/head/delete?deductionId="+payload);

export const fetchsalaryGradeList = () => get("/salary/grade/list");
export const saveSalaryGrade = (payload) => post("/salary/grade/save",payload);
export const updateSalaryGrade = (payload) => post("/salary/grade/update",payload);
export const saveSalaryGradeConfiguration = (payload) => post("/salary/grade/configure",payload);
export const deleteSalaryGrade = (payload) => del("/salary/grade/delete?gradeId="+payload);

export const fetchsaveEmployeeSalaryConfigurationList = () => get("/employee/salary/configuration/list");
export const saveEmployeeSalaryConfigurationByGrade = (payload) => post("/employee/salary/configuration/by/grade",payload);
export const saveEmployeeSalaryConfigurationByManual = (payload) => post("/employee/salary/configuration/by/manual",payload);

export const fetchbankAdviseContentView = () => get("/bank/advise/content/view");
export const updatebankAdviseContentView = (payload) => post("/bank/advise/content/save",payload);

export const fetchviewSalaryDefaultConfiguration = () => get("/salary/default/configuration/view");
export const saveSalaryDefaultConfiguration = (payload) => post("/salary/default/configuration/save",payload);

export const fetchsalarySheetList = (payload) => get(`/salary/sheet/list?salaryMonth=${payload.salaryMonth}&salaryYear=${payload.salaryYear}`);
export const deleteSalarySheet = (payload) => del("/salary/sheet/delete?sheetId="+payload);
export const paymentSalarySheet = (payload) => post("/salary/sheet/payment",payload);
export const updateSalarySheet = (payload) => post("/salary/sheet/update",payload);