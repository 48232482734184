import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import TableView from '../../../contents/AntTableResponsive';
import { v4 as uuidv4 } from "uuid";
import { SelectPartnerList } from '../../select/SelectPartnerList';

export default function PartnerMapping() {

  
    const [updateForm] = Form.useForm();
    const fetchMasterSettingUserList = useStoreActions((state) => state.masterSettings.fetchMasterSettingUserList)
    const masterSettingUserList = useStoreState((state) => state.masterSettings.masterSettingUserList);
    const deleteMasterSettingUser = useStoreActions((state) => state.masterSettings.deleteMasterSettingUser);
    const mapUserAsaPartner = useStoreActions((state) => state.masterSettings.mapUserAsPartner);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const loading = useStoreState((state) =>  state.masterSettings.loading);

    const [userName, setUserName] = useState(null);
  
    useEffect(() => {
        fetchMasterSettingUserList();
    }, []);

    const userDelete = (e) => {
        deleteMasterSettingUser(e)
    }

    const updateSubmitForm = (value) => {
        var postData = {
            userName: userName,
            partnerId: value.partnerId,
          };
          mapUserAsaPartner(postData);
          setIsModalVisible(false);
    }

    let columns = [
        {title: 'Serial', dataIndex: 'serial', key: uuidv4(), showOnResponse: true, showOnDesktop: true, render: (text: any, record: any, index) => (
            <span>{index + 1}</span>
        )},
        {title: 'User Name', dataIndex: 'username', key: 'username', showOnResponse: true, showOnDesktop: true},
        {title: 'Nick name', dataIndex: 'nickName', key: 'nickName', showOnResponse: true, showOnDesktop: true},
        {title: 'Mobile', dataIndex: 'mobileNo', key: 'mobileNo', showOnResponse: true, showOnDesktop: true},
        {title: 'Status', dataIndex: 'status', key: 'status', showOnResponse: true, showOnDesktop: true},
        {title: 'Partner Name', dataIndex: 'partnerName', key: 'partnerName', showOnResponse: true, showOnDesktop: true},
        {title: 'Action', dataIndex: 'userId', key: 'userId', showOnResponse: true, showOnDesktop: true, render: (text: any, record: any, index) => (
            <Space size="middle">
                <Button type='primary' icon={<EditOutlined />} onClick={() => {
                    setIsModalVisible(true);
                    setUserName(record.username);
                }}/>
                <Popconfirm
                    title="Are you sure to delete this?"
                    okText="Yes"
                    disabled={record.username==='arif'?true:false}
                    cancelText="No"
                    onConfirm={() => userDelete(record?.username)}
                >
                        <Tooltip title="Delete" >
                            <Button disabled={record.username==='arif'?true:false} danger icon={<DeleteOutlined />} />
                        </Tooltip>
                </Popconfirm>
            </Space>
         )}
    ]

    const [current, setcurrent]= useState<any>(1)

    const handlePagination= (val)=>{
        setcurrent(val)
    }
    return(
        <>
            <Card title="User List">
              
                <Row className="m-t-mo-30">
                    <Col span="24">
                    <Skeleton loading={loading} paragraph={{rows:10}} />
                        {!loading &&
                        <div className="datatable-responsive-demo">
                            {masterSettingUserList?.length > 0 &&
                                <TableView
                                antTableProps={{
                                    showHeader: true,
                                    columns,
                                    dataSource: masterSettingUserList,
                                    filterData: masterSettingUserList,
                                    pagination: { onChange: handlePagination, current:current },
                                    bordered: true,
                                    rowKey:"id",
                                }}
                                mobileBreakPoint={768}
                            />
                            }
                        </div>
}
                    </Col>
                </Row>
            </Card>
            <Modal
                title="Update"
                visible={isModalVisible}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Update"
                centered
                maskClosable={false}
            >
                <Form
                    layout="vertical"
                    id="update"
                    onFinish={updateSubmitForm}
                    form={updateForm}
                    
                >
                    <Row>
     
                        <Col span={24}>
                            <Form.Item
                                name="partnerId"
                                label="Partner "
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select partner" },
                                ]}
                            >
                                <SelectPartnerList />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}