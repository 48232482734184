import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, Modal, Popconfirm, Row, Space, Tooltip, Upload } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";
import moment from 'moment';

export default function EmployeeBankAccount(props) {

    const [updateForm] = Form.useForm();

    const enabledStaffBasicInfoList = useStoreState((state) => state.staff.staffBasicInfoListReport);
    const fetchenabledStaffBasicInfoList = useStoreActions((state) => state.staff.fetchstaffBasicInfoListReport);
    const updateStaffBankAccount = useStoreActions((state) => state.staff.updateStaffBankAccount);

    useEffect(() => {
        fetchenabledStaffBasicInfoList();
    }, [])


    const updateFomrSubmit = (value) => {
        value.staffId = staffId;
        updateStaffBankAccount(value)
        //updateDesignation(postdata);
        setIsModalVisible(false);
    }

    const [staffId, setstaffId] = useState<any>();

    const columns = [

        {
            title: 'Serial',
            dataIndex: 'staffSerial',
            key: 'staffSerial',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Name',
            dataIndex: 'staffName',
            key: 'staffName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Designation',
            dataIndex: 'designationName',
            key: 'designationName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Bank Name',
            dataIndex: 'bankName',
            key: 'bankName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: "Branch Name ",
            dataIndex: 'branchName',
            key: 'branchName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: "Acoount No",
            dataIndex: 'accountNo',
            key: 'accountNo',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Edit',
            key: 'staffId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Edit">
                        <Button type='primary' icon={<EditOutlined />} onClick={() => {
                            setIsModalVisible(true);
                            setstaffId(record.staffId);
                            updateForm.setFieldsValue({
                                staffName: record.staffName,
                                customStaffId: record.customStaffId,
                                branchName: record.branchName,
                                accountNo: record.accountNo,
                                bankName: record.bankName,
                            });
                        }}
                        />
                    </Tooltip>

                </Space>
            ),
        }
    ];

    const [isModalVisible, setIsModalVisible] = useState(false);

    return (
        <>
            <Card title="Employee Bank Account" >
                <div className="datatable-responsive">
                    <Table
                        antTableProps={{
                            showHeader: true,
                            columns,
                            dataSource: enabledStaffBasicInfoList,
                            pagination: false,
                            bordered: true,
                            rowKey: 'staffId'
                        }}
                        mobileBreakPoint={768}
                    />
                </div>

            </Card>

            <Modal
                title="Update Info"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => { setIsModalVisible(false); updateForm.resetFields() }}
                cancelText="Close"
                okText="Update"
                centered
            >
                <Form
                    layout="vertical"

                    onFinish={updateFomrSubmit}
                    id="update"
                    form={updateForm}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item
                                name="customStaffId"
                                label="ID:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input staff id" },
                                ]}
                            >
                                <Input placeholder='Staff Id'  disabled/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item
                                name="staffName"
                                label="Name:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input staff name" },
                                ]}
                            >
                                <Input placeholder='Staff Name' disabled />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item
                                name="bankName"
                                label="Bank Name:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input bank name" },
                                ]}
                            >
                                <Input placeholder='Bank Name' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item
                                name="branchName"
                                label="Branch Name:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input branch name" },
                                ]}
                            >
                                <Input placeholder='Branch Name' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item
                                name="accountNo"
                                label="Account No:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input account no" },
                                ]}
                            >
                                <Input placeholder='Account No' />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

            </Modal>
        </>
    )
}
