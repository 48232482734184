import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import Column from 'rc-table/lib/sugar/Column';
import { EditOutlined, SwitcherOutlined } from '@ant-design/icons';
import TableView from '../../../contents/AntTableResponsive';
const { Option } = Select;

export default function InstiuteListCore() {
    const fetchInstiuteListcore = useStoreActions((state)=> state.customerSupport.fetchInstiuteListcore);
    const instiuteListcore = useStoreState((state) => state.customerSupport.instiuteListcore);
    const instituteBillingCoreInfoUpdate = useStoreActions((state) => state.customerSupport.instituteBillingCoreInfoUpdate);

    const institutePackageUpdate = useStoreActions((state) => state.customerSupport.institutePackageUpdateFromBilling);
    const activeInstitute = useStoreActions((state) => state.customerSupport.submitActiveInstituteFromBilling);
    const deactiveInstitute = useStoreActions((state) => state.customerSupport.submitDeactiveInstituteFromBilling);
    const updateInstituteStatus = useStoreActions((state) => state.customerSupport.updateInstituteStatus);
   
    useEffect(() => {
        fetchInstiuteListcore();
    }, []);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isInstituteStatusModalVisible, setInstituteStatusModalVisible] = useState(false);
    const [isInstitutePackageModalVisible, setInstitutePackageModalVisible] = useState(false);
    const [instituteId, setinstituteId] = useState<any>(null);
    const [updateform] = Form.useForm();
    const [statusUpdateform] = Form.useForm();


    const columns = [
        { title: 'Institute ID', dataIndex: 'instituteId', key: "instituteId", showOnResponse: true, showOnDesktop: true  },
        { title: 'Institute Name', dataIndex: 'instituteName', key: "instituteName", showOnResponse: true, showOnDesktop: true  },
        { title: 'Address', dataIndex: 'address', key: "address", showOnResponse: true, showOnDesktop: true  },
        { title: 'Contact Number', dataIndex: 'contactNumber', key: "contactNumber", showOnResponse: true, showOnDesktop: true  },
        { title: 'Partner', dataIndex: 'partnerName', key: "partnerName", showOnResponse: true, showOnDesktop: true  },
        { title: 'Type', dataIndex: 'instituteType', key: "instituteType", showOnResponse: true, showOnDesktop: true  },
        { title: 'Bill Cycle', dataIndex: 'billCycle', key: "billCycle", showOnResponse: true, showOnDesktop: true  },
        { title: 'Bill Type', dataIndex: 'billType', key: "billType", showOnResponse: true, showOnDesktop: true  },
        { title: 'Bill Payable Person', dataIndex: 'billPayablePerson', key: "billPayablePerson", showOnResponse: true, showOnDesktop: true  },
        { title: 'Package', dataIndex: 'packageName', key: "packageName", showOnResponse: true, showOnDesktop: true  },
        { title: 'Bill Rate', dataIndex: 'billRate', key: "billRate", showOnResponse: true, showOnDesktop: true  },
        { title: 'Total Student', dataIndex: 'totalStudent', key: "totalStudent", showOnResponse: true, showOnDesktop: true  },
        { title: 'Minimum Student', dataIndex: 'minimumStudent', key: "minimumStudent", showOnResponse: true, showOnDesktop: true  },
        { title: 'Institute Status', dataIndex: 'instituteStatus', key: "instituteStatus", showOnResponse: true, showOnDesktop: true  },

        { title: 'Bill Info Edit', dataIndex: 'status', key: "status", showOnResponse: true, showOnDesktop: true, render: (text, record:any, index) =>
            <>
                <Space size="middle">
                    <Tooltip title="Edit Bill Info">
                        <Button type='primary' icon={<EditOutlined />} onClick={() => {
                            setIsModalVisible(true);
                            setinstituteId(record?.instituteId);
                            updateform.setFieldsValue({
                                billCycle: record.billCycle,
                                billPayablePerson: record.billPayablePerson,
                                billRate: record.billRate,
                                billType: record.billType,
                                minimumStudent: record.minimumStudent,
                            });
                        }}
                        />
                    </Tooltip>
                </Space>
            </>
        },

        { title: 'Edit Status', dataIndex: 'status', key: "status", showOnResponse: true, showOnDesktop: true, render: (text, record:any, index) =>
            <>
                <Space size="middle">
                    <Tooltip title="Edit Status">
                        <Button type='primary' icon={<EditOutlined />} onClick={() => {
                            setInstituteStatusModalVisible(true);
                            setinstituteId(record?.instituteId);

                             statusUpdateform.setFieldsValue({
                                instituteStatus:record?.status
                            });
     
                        }}
                        />
                    </Tooltip>
                </Space>
            </>
        },

        { title: 'Edit Package', dataIndex: 'status', key: "status", showOnResponse: true, showOnDesktop: true, render: (text, record:any, index) =>
            <>
                <Space size="middle">
                    <Tooltip title="Edit Package">
                        <Button type='primary' icon={<EditOutlined />} onClick={() => {
                            setInstitutePackageModalVisible(true);
                            setinstituteId(record?.instituteId);
                            updateform.setFieldsValue({
                                packageId: record.packageId,
                            });
     
                        }}
                        />
                    </Tooltip>
                </Space>
            </>
        },
    ];

    const updateInfoForm = (value) => {
        value.instituteId= instituteId;
        instituteBillingCoreInfoUpdate(value);
        setIsModalVisible(false)
    };

    const activeInactiveInstitute = (value) => {
       
        if(value.instituteStatus==1){
            activeInstitute(instituteId);  
        }
        if(value.instituteStatus==0){
            deactiveInstitute(instituteId);
        }


        setInstituteStatusModalVisible(false);
    };

    const changeInstituteStatus = (value) => {

        let payload = {
            "instituteId": instituteId,
            "instituteStatus": value.instituteStatus,
        }

        updateInstituteStatus(payload);

        setInstituteStatusModalVisible(false);
    };

    const editPackageInfo = (value) => {
        let payload = {
            "instituteId": instituteId,
            "packageId": value.packageId,
        }
        institutePackageUpdate(payload);
        setInstitutePackageModalVisible(false);

    };

    return(
        <>
           <Card title="Institute List">
           <Row className="m-t-mo-30">
                <Col span={24}>
                    <div className="datatable-responsive-demo">
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns,
                                dataSource: instiuteListcore,
                                filterData: instiuteListcore,
                                pagination: true,
                                bordered: true,
                                rowKey: "instituteId",
                            }}
                            mobileBreakPoint={768}
                        />
                    </div>
                </Col>
            </Row>
           </Card>
           
           <Modal
                title="Institute Core Information"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Update"
                centered
            >
                <Form
                    layout="vertical"
                    onFinish={updateInfoForm}
                    id="update"
                    form={updateform}
                >

                    <Form.Item
                        name="billCycle"
                        label="Bill Cycle"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please Select" },
                        ]}
                    >
                        <Select placeholder="Bill Cycle">
                            <Option key={0} value="MONTHLY">Monthly</Option>
                            <Option key={1} value="HALF_YEARLY">Half Yearly</Option>
                            <Option key={2} value="YEARLY">Yearly</Option>
                            <Option key={4} value="N/A">N/A</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="billPayablePerson"
                        label="Bill Payable Person"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please Select" },
                        ]}
                    >
                        <Select placeholder="Bill Payable Person">
                            <Option key={0} value="INSTITUTE">Institute</Option>
                            <Option key={1} value="STUDENT">Student</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="billType"
                        label="Bill Type"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please Select" },
                        ]}
                    >
                        <Select placeholder="Bill Type">
                            <Option key={0} value="FIXED">Fixed</Option>
                            <Option key={1} value="PER_STUDENT">Per Student</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="billRate"
                        label="Bill Rate"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Bill Rate" },
                        ]}
                    >
                        <Input placeholder="Bill Rate" />

                    </Form.Item>

                    <Form.Item
                        name="minimumStudent"
                        label="Minimum Student"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Minimum Student" },
                        ]}
                    >
                        <Input placeholder="Minimum Student" />

                    </Form.Item>

                </Form>

            </Modal>

            <Modal
                title="Institute Status"
                visible={isInstituteStatusModalVisible}
                okButtonProps={{ form: 'updateStatus', htmlType: 'submit' }}
                onCancel={() => setInstituteStatusModalVisible(false)}
                cancelText="Close"
                okText="Update"
                centered
            >
                <Form
                    layout="vertical"
                    onFinish={changeInstituteStatus}
                    id="updateStatus"
                    form={statusUpdateform}
                >

                    <Form.Item
                        name="instituteStatus"
                        label="Institute Status"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please Select" },
                        ]}
                    >
                        <Select placeholder="Institute Status">
                            <Option key={1} value={1}>Active</Option>
                            <Option key={0} value={0}>Inactive</Option>
                            <Option key={2} value={2}>Pending</Option>
                        </Select>
                    </Form.Item>

                </Form>

            </Modal>

            <Modal
                title="Edit Package"
                visible={isInstitutePackageModalVisible}
                okButtonProps={{ form: 'updatePackage', htmlType: 'submit' }}
                onCancel={() => setInstitutePackageModalVisible(false)}
                cancelText="Close"
                okText="Update"
                centered
            >
                <Form
                    layout="vertical"
                    onFinish={editPackageInfo}
                    id="updatePackage"
                    form={updateform}
                >

                    <Form.Item
                        name="packageId"
                        label="Institute Package"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please Select" },
                        ]}
                    >
                        <Select placeholder="Institute Package">
                            <Option key={1} value={1}>Basic</Option>
                            <Option key={2} value={2}>Premium</Option>
                            <Option key={3} value={3}>SBL</Option>
                        </Select>
                    </Form.Item>

                </Form>

            </Modal>
        </>
    )
}