import { Select } from "antd";
import * as React from "react";
import { useEffect } from "react";
import { useStoreActions, useStoreState } from "../../store/hooks/easyPeasy";
const { Option } = Select;

export interface SelectPayRollAddition2 {
  onChange?: any;
  selected?: any;
  defaultSelected?: any;
  style?: any
}

export const SelectPayRollAddition2 = ({
  onChange,
  selected,
  defaultSelected,
  style
}: SelectPayRollAddition2) => {
  const itemList = useStoreState((state) => state.payroll.salaryHeadListAddition);

  const itemListFetch = useStoreActions((state) => state.payroll.fetchsalaryHeadListAddition);

  useEffect(()=>{
    itemListFetch();
  },[])

  const onSelect = (id) => {
    if (itemList) {
      const val = itemList.find((item) => item.additionId === id);
      onChange(val?.additionId);
    }
  };




  return (
    <Select
      onChange={onSelect}
      // loading={loading}
      showSearch
      // allowClear
      defaultValue={defaultSelected}
      value={selected}
      style={style}
      allowClear
      placeholder="Select"
      filterOption={(input, option:any) =>
        option !== undefined &&
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {itemList ? (
        itemList.map((type, idx) => (
          <Option key={type.additionId} value={type.additionId}>
            {type.headName}
          </Option>
        ))
      ) : (
        <Option value="fetching">Fetching List</Option>
      )}
    </Select>
  );
};
