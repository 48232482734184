import { post, get, del, put, delBulk, postFile} from "../http";
export const fetchInstituteInfoUrl = () => get("/institute/view");
export const updateInstituteInfoUrl = (payload) => post("/institute/update", payload);
export const updatePhoto = (payload) => postFile("/institute/photo/update", payload);
export const defaultSignatureListUrl = () => get("/signature/image/list"); 
export const saveDefaultSignatureUrl = (payload) => post("/signature/save", payload);
export const deleteDefaultSignatureUrl = (payload) => get('/signature/delete?signatureId='+payload); 
export const signatureImageUpdate = (payload, id) => postFile("/signature/image/update?signatureId="+id, payload);
export const staffListUrl = () => get('/staff/report/basic/info/light/list');
export const masterSettingsUserList = () =>  get("/user/list");
export const saveMasterSettingUser = (payload) => post('/user/create', payload);
export const updateMasterSettingUserUrl = (payload) => post('/user/update', payload);
export const deleteMasterSettingUserUrl = (payload) => del('/user/delete?username='+payload);
export const mapUserAsPartner = (payload) => get('/partner/info/mapping?partnerId='+payload.partnerId+'&userName='+payload.userName);
