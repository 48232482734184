import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, InputNumber, Popconfirm, Row, Space, Tooltip, Modal, Skeleton } from "antd";
import { DeleteOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import { useStoreActions, useStoreState } from "../../../store/hooks/easyPeasy";
import TableView from "../../../contents/AntTableResponsive";

export default function ZktecoDeviceSettings() {

  const saveZktecoDevice = useStoreActions((state) => state.customerSupport.saveZktecoDeviceInfos);
  const fetchZktecoDevices = useStoreActions((state) => state.customerSupport.fetchZktecoDeviceInfos);
  const zktecoDeviceList = useStoreState((state) => state.customerSupport.zktecoDeviceInfos);

  const updateZktecoDevice = useStoreActions((state) => state.customerSupport.updateZktecoDeviceInfos);
  const deleteZktecoDevice = useStoreActions((state) => state.customerSupport.deleteZktecoDeviceInfos);

  const [deviceSaveForm] = Form.useForm();
  const [deviceUpdateForm] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const loading = useStoreState((state) => state.inventory.loading);
  const [recordId,setRecordId] = useState<any>();

  const zktecoDeviceSave = (value) => {
    let postData = {
        deviceId: value.deviceId,
        deviceName: value.deviceName,
    };
    saveZktecoDevice(postData);
  };

  const updZktecoDeviceInfo = (value) => {
    let postData = {
        recordId: recordId,
        deviceId: value.deviceId,
        deviceName: value.deviceName,
     
    };
    updateZktecoDevice(postData);
    setIsModalVisible(loading);

  };

  useEffect(() => {
    fetchZktecoDevices();

  }, [])


  const columns = [
    {
      title: 'Device ID',
      dataIndex: 'deviceId',
      key: 'deviceId',
      showOnResponse: true,
      showOnDesktop: true
    },
    {
      title: 'Device Name',
      dataIndex: 'deviceName',
      key: 'deviceName',
      showOnResponse: true,
      showOnDesktop: true
    },    
    

    {
      title: 'Action',
      key: 'recordId',
      showOnResponse: true,
      showOnDesktop: true,
      render: (text: any, record: any, index) => (
        <Space size="middle">

          <Tooltip title="Edit">
            <Button type='primary' icon={<EditOutlined />} onClick={() => {
              setIsModalVisible(true);
              setRecordId(record.recordId);
               deviceUpdateForm.setFieldsValue({
                deviceId: record.deviceId,
                deviceName: record.deviceName,
              });
            }}
            />
          </Tooltip>
          <Popconfirm
            title="Are you sure to delete this?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => deleteZktecoDevice(record?.recordId)}
          >
            <Tooltip title="Delete">
              <Button danger icon={<DeleteOutlined />} />
            </Tooltip>
          </Popconfirm>

        </Space>
      ),
    },


  ];

  return (
    <>
      <Card title="Zkteco Device Info">
        <Row>
          <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 3 }} xl={{ span: 20, offset: 3 }}>
            <Form
              layout="vertical"
              id="deviceInfo"
              onFinish={zktecoDeviceSave}
              form={deviceSaveForm}
            >
              <Row>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    name="deviceId"
                    label="Device Id"
                    className="title-Text"
                    rules={[
                      {
                        required: true,
                        message: "Please write deviceId",
                      },
                    ]}
                  >
                    <Input placeholder="Enter deviceId" />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    name="deviceName"
                    label="Device Name"
                    className="title-Text"
                    rules={[
                      { required: true, message: "Please write deviceName" },
                    ]}
                  >
                    <Input placeholder="Enter deviceName" />
                  </Form.Item>
                </Col>

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Space size="small">
                    <Button
                      type="primary"
                      htmlType="submit"
                      icon={<SaveOutlined />}
                    >
                      Save
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>

        <Skeleton loading={loading} paragraph={{ rows: 10 }} />

        <Row className="m-t-mo-30">
          <Col span="24">
            <div className="datatable-responsive-demo">
              <TableView
                antTableProps={{
                  showHeader: true,
                  columns,
                  dataSource: zktecoDeviceList,
                  filterData: zktecoDeviceList,
                  pagination: true,
                  bordered: true,
                  rowKey: "recordId",

                }}
                mobileBreakPoint={768}
              />
            </div>
          </Col>
        </Row>

      </Card>

      <Modal
        title="Defice Info Edit"
        visible={isModalVisible}
        okButtonProps={{ form: 'update', htmlType: 'submit' }}
        onCancel={() => setIsModalVisible(false)}
        cancelText="Close"
        okText="Update"
        centered
      >
        <Form
          layout="vertical"
          onFinish={updZktecoDeviceInfo}
          id="update"
          form={deviceUpdateForm}
        >

          <Form.Item
            name="deviceId"
            label="Device Id : "
            className="title-Text"
            rules={[
              { required: true, message: "Please input deviceId" },
            ]}
          >
            <Input placeholder='deviceId' />
          </Form.Item>
          
          <Form.Item
            name="deviceName"
            label="Device Name"
            className="title-Text"
            rules={[
              { required: true, message: "Please write deviceName" },
            ]}
          >
            <Input placeholder="Enter deviceName" />
          </Form.Item>


        </Form>

      </Modal>

    </>
  );
}
