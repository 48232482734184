import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, Table, notification } from 'antd'
import { DeleteOutlined, EditOutlined, SaveOutlined, SettingOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import TableView from '../../../../contents/AntTableResponsive';
import { moneyFormat } from '../../../../utils/utils';


export default function SalaryGrade() {
    const salaryGradeList = useStoreState((state) => state.payroll.salaryGradeList);
    const fetchsalaryGradeList = useStoreActions((state) => state.payroll.fetchsalaryGradeList);
    const saveSalaryGradeConfiguration = useStoreActions((state) => state.payroll.saveSalaryGradeConfiguration);
    const saveSalaryGrade = useStoreActions((state) => state.payroll.saveSalaryGrade);
    const updateSalaryGrade = useStoreActions((state) => state.payroll.updateSalaryGrade);
    const deleteSalaryGrade = useStoreActions((state) => state.payroll.deleteSalaryGrade);


    const [saveForm] = Form.useForm();
    const [updateForm] = Form.useForm();
    const [tableData, setTableData] = useState<any>()
    const [tableData2, setTableData2] = useState<any>()

    ///modal
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisible2, setIsModalVisible2] = useState(false);
    const [updateId, setUpdateid] = useState<any>();

    useEffect(() => {
        fetchsalaryGradeList();
    }, [])



    const createSubmitForm = (value) => {
        saveSalaryGrade(value);
        saveForm.resetFields();
    }

    /////////////

    const updateSubmitForm = (value) => {
        value.gradeId = updateId;
        updateSalaryGrade(value);
        setIsModalVisible(false);
        updateForm.resetFields();
    }

    const columns = [
        {
            title: 'Grade Name',
            dataIndex: 'gradeName',
            key: 'gradeName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Serial',
            dataIndex: 'serial',
            key: 'serial',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                moneyFormat(record.serial)
            )
        },
        {
            title: 'Note',
            dataIndex: 'note',
            key: 'note',
            showOnResponse: true,
            showOnDesktop: true
        },        
        {
            title: 'Gross Salary',
            dataIndex: 'grossSalary',
            key: 'grossSalary',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Deduction Amount',
            dataIndex: 'deductionAmount',
            key: 'deductionAmount',
            showOnResponse: true,
            showOnDesktop: true
        },        
        {
            title: 'Net Salary',
            dataIndex: 'netSalary',
            key: 'netSalary',
            showOnResponse: true,
            showOnDesktop: true
        },        
        {
            title: 'Additions',
            dataIndex: 'additions',
            key: 'additions',
            showOnResponse: true,
            showOnDesktop: true
        },        
        {
            title: 'Deductions',
            dataIndex: 'deductions',
            key: 'deductions',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Action',
            key: 'ss',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Edit">
                        <Button type='primary' icon={<EditOutlined />} onClick={() => {
                            updateForm.setFieldsValue({
                                gradeName: record.gradeName,
                                serial: record.serial,
                                note: record.note,
                            });
                            setIsModalVisible(true);
                            setUpdateid(record.gradeId);

                        }} />
                    </Tooltip>
                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deleteSalaryGrade(record?.gradeId)}
                    >
                        <Tooltip title="Delete">
                            <Button danger icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>

                </Space>
            ),
        },
        {
            title: 'Configure',
            key: 'ss',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Configure">
                        <Button type='primary' icon={<SettingOutlined />} onClick={() => {
                            setIsModalVisible2(true);
                            setUpdateid(record.gradeId);
                            setTableData([]);
                            setTableData2([]);
                            setTimeout(() => {
                                setTableData(record?.additionList);
                                setTableData2(record?.deductionList);     
                            }, 100);


                        }} />
                    </Tooltip>
                </Space>
            ),
        }
    ];

    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
        // console.log(value)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const [selectedRowKeys2, setselectedRowKeys2] = useState<any>([]);
    const [selectedValue2, setselectedValue2] = useState<any>([]);

    const onSelectChange2 = (selectedRowKeys2, value) => {
        setselectedRowKeys2(selectedRowKeys2);
        setselectedValue2(value);
        // console.log(value)
    };

    const rowSelection2 = {
        selectedRowKeys: selectedRowKeys2,
        onChange: onSelectChange2,
    };

    const additioncols = [
        {
            title: 'Head Name',
            dataIndex: 'additionHeadName',
            key: 'additionHeadName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Input value={record?.amount} onChange={onchangeValue("amount", record, index)} style={{ width: "100%" }} />
            ),
        },
    ];

    const deductioncols = [
        {
            title: 'Head Name',
            dataIndex: 'deductionHeadName',
            key: 'deductionHeadName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Input value={record?.amount}  onChange={onchangeValue2("amount", record, index)} style={{ width: "100%" }} />
            ),
        },
    ];

    const onchangeValue: any =
    (key, data, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const newData = [...tableData];
        newData[index][key] = e.target.value;
        setTableData(newData);
    };

    const onchangeValue2: any =
    (key, data, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const newData = [...tableData2];
        newData[index][key] = e.target.value;
        setTableData2(newData);
    };

    const updateData = () =>{
        if (selectedRowKeys.length==0 ){
            notification.error({message:"Please select first"});
            return;
        };
        const additionList = selectedValue?.map(item=>{
            return {
                "additionHeadId": item?.additionHeadId,
                "amount": item?.amount
            }
        });
        const deductionList = selectedValue2?.map(item=>{
            return {
                "deductionHeadId": item?.deductionHeadId,
                "amount": item?.amount
            }
        });

        let payload = {
            "additionList":additionList,
            "deductionList":deductionList,
            "gradeId": updateId
          };

          saveSalaryGradeConfiguration(payload);
          setIsModalVisible2(false);
          setselectedRowKeys([]);
          setselectedRowKeys2([]);
          setselectedValue([]);
          setselectedValue2([]);

        
    }
    return (
        <Card title="Grade">
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24, offset: 2 }} xl={{ span: 24, offset: 2 }}>
                    <Form
                        layout="vertical"
                        id="sessionInfo"
                        onFinish={createSubmitForm}
                        form={saveForm}
                    >
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="gradeName"
                                    label="Grade Name"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please write grade name" },
                                    ]}
                                >
                                    <Input placeholder="Grade Name" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="serial"
                                    label="Serial"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please input serial" },
                                    ]}
                                >
                                    <InputNumber placeholder="Serial" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="note"
                                    label="Note"
                                    className="title-Text"
                                // rules={[
                                //     { required: true, message: "Please write serial number" },
                                // ]}
                                >
                                    <Input placeholder="Note" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 2 }} xl={{ span: 2 }}>
                                <Space size="small" >
                                    <Button type="primary" htmlType="submit" icon={<SaveOutlined />} >
                                        Save
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Row className="m-t-mo-30">
                <Col span="24">
                    <div className="datatable-responsive-demo">
                        {salaryGradeList?.length > 0 &&
                            <TableView
                                antTableProps={{
                                    showHeader: true,
                                    columns,
                                    dataSource: salaryGradeList,
                                    filterData: salaryGradeList,
                                    pagination: true,
                                    bordered: true,
                                    rowKey: "key",
                                    //rowKey:record => record.gradeId
                                }}
                                mobileBreakPoint={768}
                            />
                        }
                    </div>
                </Col>
            </Row>
            <Modal
                title="Update"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Update"
                centered
                maskClosable={false}
            >
                <Form
                    layout="vertical"
                    id="update"
                    onFinish={updateSubmitForm}
                    form={updateForm}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="gradeName"
                                label="Grade Name"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please write grade name" },
                                ]}
                            >
                                <Input placeholder="Grade Name" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="serial"
                                label="Serial"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input serial" },
                                ]}
                            >
                                <InputNumber placeholder="Basic Amount" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="note"
                                label="Note"
                                className="title-Text"
                            // rules={[
                            //     { required: true, message: "Please write serial number" },
                            // ]}
                            >
                                <Input placeholder="Note" />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </Modal>
            <Modal
                title="Update"
                visible={isModalVisible2}
                onCancel={() => {setIsModalVisible2(false); setselectedRowKeys([]); setselectedRowKeys2([]); setselectedValue([]); setselectedValue2([])}}
                cancelText="Close"
                okButtonProps={{ style: { display: 'none' } }}
                centered
                maskClosable={false}
                width={"80%"}
            >
                <Row>
                    <Col span={12}>
                        <Table
                            columns={additioncols}
                            dataSource={tableData}
                            bordered
                            pagination={false}
                            title={() => 'Additions'}
                            rowKey={"additionHeadId"}
                            rowSelection={rowSelection}
                        />

                    </Col>
                    <Col span={12}>
                        <Table
                            columns={deductioncols}
                            dataSource={tableData2}
                            bordered
                            pagination={false}
                            title={() => 'Dedcutions'}
                            rowKey={"deductionHeadId"}
                            rowSelection={rowSelection2}
                        />

                    </Col>
                </Row>
                <Button type='primary' style={{float:'right'}} icon={<SaveOutlined/>} onClick={()=>updateData()}>Save</Button> <br />
            </Modal>
        </Card>
    )

}