import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Form, Input, Modal, Popconfirm, Row, Select, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';




export default function ManulBillCreate(props) {
    const { Option } = Select;

    const [form] = Form.useForm();
    const createInstituteBillManual = useStoreActions((state) => state.customerSupport.createInstituteBillManual);

    const formSubmit = (value) => {
        createInstituteBillManual(value);
       // form.resetFields();
    }

    let year = new Date().getFullYear();


    return (
        <>
            <Card title="Institute Bill Create Manual" >
                <Form
                    layout="vertical"
                    onFinish={formSubmit}
                    id="basic-info"
                    form={form}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item
                                name="instituteId"
                                label="Institute Id "
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please insert institute id" },
                                ]}
                            >
                                <Input placeholder="Institute Id" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item
                                name="billCycle"
                                label="Bill Cycle"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please Select" },
                                ]}
                            >
                                <Select placeholder="Bill Cycle">
                                    <Option key={0} value="MONTHLY">Monthly</Option>
                                    <Option key={1} value="HALF_YEARLY">Half Yearly</Option>
                                    <Option key={2} value="YEARLY">Yearly</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item
                                name="billType"
                                label="Bill Type"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please Select" },
                                ]}
                            >
                                <Select placeholder="Bill Type">
                                    <Option key={0} value="PER_STUDENT">Per Student</Option>
                                    <Option key={1} value="FIXED">Fixed</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item
                                name="billYear"
                                label="Bill Year:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select year" },
                                ]}
                                style={{ width: "100%" }}
                            >
                                <Select placeholder="Select Year" allowClear style={{ width: "100%" }}>
                                    <Option value={year - 1}>{year - 1}</Option>
                                    <Option value={year}>{year}</Option>
                                    <Option value={year + 1}>{year + 1}</Option>
                                </Select>

                            </Form.Item>
                        </Col>


                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item
                                name="billingMonth"
                                label="Month:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select month" },
                                ]}
                            >
                                <Select placeholder="Select Month" allowClear style={{ width: "100%" }}>
                                    <Option value="">Not Applicable</Option>
                                    <Option value="January-December">January-December</Option>
                                    <Option value="January-June">January-June</Option>
                                    <Option value="July-December">July-December</Option>
                                    <Option value="January">January</Option>
                                    <Option value="February">February</Option>
                                    <Option value="March">March</Option>
                                    <Option value="April">April</Option>
                                    <Option value="May">May</Option>
                                    <Option value="June">June</Option>
                                    <Option value="July">July</Option>
                                    <Option value="August">August</Option>
                                    <Option value="September">September</Option>
                                    <Option value="October">October</Option>
                                    <Option value="November">November</Option>
                                    <Option value="December">December</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item
                                name="billRate"
                                label="Bill Rate"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please insert bill rate" },
                                ]}
                            >
                                <Input.TextArea placeholder="Bill Rate" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item
                                name="totalStudent"
                                label="Total Student"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please insert total student" },
                                ]}
                            >
                                <Input.TextArea placeholder="Total Student" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item
                                name="actualBillAmount"
                                label="Actual Bill Amount"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please insert actual bill amount" },
                                ]}
                            >
                                <Input.TextArea placeholder="Actual Bill Amount" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item
                                name="discountAmount"
                                label="Discount Amount"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please insert discount amount" },
                                ]}
                            >
                                <Input.TextArea placeholder="Discount Amount" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item
                                name="payableAmount"
                                label="Payable Amount"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please insert payable amount" },
                                ]}
                            >
                                <Input.TextArea placeholder="Payable Amount" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item
                                name="commission"
                                label="Commission"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please insert commission" },
                                ]}
                            >
                                <Input.TextArea placeholder="Commission" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item
                                name="companyAmount"
                                label="Company Amount"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please insert company amount" },
                                ]}
                            >
                                <Input.TextArea placeholder="Company Amount" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item
                                name="note"
                                label="Note"
                                className="title-Text"

                            >
                                <Input.TextArea placeholder="Note" />
                            </Form.Item>
                        </Col>


                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Space size="small" style={{ marginTop:30 }}>
                                <Button type="primary" className='mt-0' htmlType="submit" icon={<SaveOutlined />}>
                                    Save
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>



            </Card>
        </>
    )
}
