import { DeleteOutlined, EditOutlined, SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Card, Select, Col, DatePicker, Form, Input, Modal, notification, Popconfirm, Row, Space, Tooltip, Upload } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import TextArea from 'antd/lib/input/TextArea';

const { Option } = Select;

export default function BankAdviseContent(props) {

    const [form] = Form.useForm();
    const bankAdviseContentView = useStoreState(state => state.payroll.bankAdviseContentView)

    const fetchbankAdviseContentView = useStoreActions((state) => state.payroll.fetchbankAdviseContentView);
    const updatebankAdviseContentView = useStoreActions((state) => state.payroll.updatebankAdviseContentView);

    useEffect(()=>{
        fetchbankAdviseContentView();
    },[fetchbankAdviseContentView])


    const formSubmit = (value) => {
        updatebankAdviseContentView(value)

    }



    useEffect(() => {
        form.setFieldsValue({
            ...bankAdviseContentView
        })
    }, [bankAdviseContentView])




    return (
        <>
            <Card title="Bank Advise Content" >
                <Form
                    layout="vertical"
                    onFinish={formSubmit}
                    id="basic-info"
                    form={form}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={3} xl={3}> </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="bankName"
                                label="Bank Name"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input bank name" },
                                ]}
                            >
                                <Input placeholder="Bank Name" />

                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="branchName"
                                label="Branch Name"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input branch name" },
                                ]}
                            >
                                <Input placeholder="Branch Name" />

                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="address"
                                label="Address"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input address" },
                                ]}
                            >
                                <Input placeholder="Address" />

                            </Form.Item>
                        </Col>



                        <Col xs={24} sm={24} md={24} lg={3} xl={3}> </Col>
                        <Col xs={24} sm={24} md={24} lg={3} xl={3}> </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="openingParagraph"
                                label="Opening Paragraph"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input opening paragraph" },
                                ]}
                            >

                                <TextArea placeholder="Opening Paragraph" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                        <Form.Item
                                name="lastParagraph"
                                label="Last Paragraph"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input last paragraph" },
                                ]}
                            >

                                <TextArea placeholder="Last Paragraph"  />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="subjectContent"
                                label="Subject Content"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input subject content" },
                                ]}
                            >
                                <TextArea placeholder="Subject Content" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={3} xl={3}> </Col>
                        <Col xs={24} sm={24} md={24} lg={3} xl={3}> </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="salutation"
                                label="Salutation"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input salutation" },
                                ]}
                            >
                                <Input placeholder="Salutation" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                        <Form.Item
                                name="signatureContent1"
                                label="Signature Content 1"
                                className="title-Text"
                            >
                                <Input placeholder="Signature Content 1" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                        <Form.Item
                                name="signatureContent2"
                                label="Signature Content 2"
                                className="title-Text"
                            >
                                <Input placeholder="Signature Content 2" />
                            </Form.Item>
                        </Col>        
                        <Col xs={24} sm={24} md={24} lg={3} xl={3}> </Col>
                        <Col xs={24} sm={24} md={24} lg={3} xl={3}> </Col>      
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                        <Form.Item
                                name="signatureContent3"
                                label="Signature Content 3"
                                className="title-Text"
                            >
                                <Input placeholder="Signature Content 3" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={3} xl={3}>

                            <Space size="small"  >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SaveOutlined />}>
                                    Save
                                </Button>
                            </Space>

                        </Col>
                        <Col xs={24} sm={24} md={24} lg={3} xl={3}> </Col>

                    </Row>


                </Form>



            </Card>


        </>
    )
}
