import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs } from 'antd'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import { SelectExpenseList } from '../../../select/SelectExpenseList';
import { SelectPayRollDeduction } from '../../../select/SelectPayRollDeduction';
import { SelectPayRollAddition } from '../../../select/SelectPayRollAddition';
import { SelectPayRollAddition2 } from '../../../select/SelectPayRollAddition2';
import { SelectLedgerPayment } from '../../../select/SelectLedgerPayment';


export default function SalaryDefaultConfig() {

    const [formUpdate] = Form.useForm();
    const fetchviewSalaryDefaultConfiguration = useStoreActions((state) => state.payroll.fetchviewSalaryDefaultConfiguration);
    const viewSalaryDefaultConfiguration = useStoreState((state) => state.payroll.viewSalaryDefaultConfiguration);
    const saveSalaryDefaultConfiguration = useStoreActions((state) => state.payroll.saveSalaryDefaultConfiguration);
    const fetchexpenseLedgerList = useStoreActions((state) => state.ledger.fetchpaymentledgerlist);
    const instiuteInfoList = useStoreState((state) => state.dashboard.instiuteInfoList);

    useEffect(() => {
        fetchexpenseLedgerList();        
    }, [])


    useEffect(() => {
        fetchviewSalaryDefaultConfiguration();
    }, []);


    const [payrollExpenseLedgerId, setpayrollExpenseLedgerId] = useState<any>(null);
    const [attendanceFineHeadId, setattendanceFineHeadId] = useState<any>(null);
    const [bonusHeadId, setbonusHeadId] = useState<any>(null);
    const [bonusOutOfHeadId, setbonusOutOfHeadId] = useState<any>(null);

    useEffect(() => {
        if (viewSalaryDefaultConfiguration !== null) {
            setpayrollExpenseLedgerId(viewSalaryDefaultConfiguration?.payrollExpenseLedgerId);
            setattendanceFineHeadId(viewSalaryDefaultConfiguration?.attendanceFineHeadId);
            setbonusHeadId(viewSalaryDefaultConfiguration?.bonusHeadId);
            setbonusOutOfHeadId(viewSalaryDefaultConfiguration?.bonusOutOfHeadId);
            formUpdate.setFieldsValue({
                payrollExpenseLedgerId: viewSalaryDefaultConfiguration?.payrollExpenseLedgerId,
                attendanceFineHeadId: viewSalaryDefaultConfiguration?.attendanceFineHeadId,
                bonusHeadId: viewSalaryDefaultConfiguration?.bonusHeadId,
                bonusOutOfHeadId: viewSalaryDefaultConfiguration?.bonusOutOfHeadId,
                bonusPercentageOfBasic: viewSalaryDefaultConfiguration?.bonusPercentageOfBasic,
            });
        }

    }, [viewSalaryDefaultConfiguration])

    const onFinish = (value) => {
        let postData: any = {
            "bonusPercentageOfBasic": value?.bonusPercentageOfBasic,
            "bonusOutOfHeadId": value?.bonusOutOfHeadId,
            "bonusHeadId": value?.bonusHeadId,
            "payrollExpenseLedgerId": value?.payrollExpenseLedgerId,
            "attendanceFineHeadId": value?.attendanceFineHeadId,
        }
        saveSalaryDefaultConfiguration(postData);
    }



    return (
        <>
            <Card title="Salary Default Configuration">
                {viewSalaryDefaultConfiguration == null && <h2 style={{ color: "red" }}>You did not set up your salary default configuration</h2>}
                <Form
                    layout="vertical"
                    id="instructionForm"
                    onFinish={onFinish}
                    form={formUpdate}
                >
                    <Row>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item
                                name="payrollExpenseLedgerId"
                                label="Payroll Expense Ledger"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select" },
                                ]}
                            >
                                <SelectLedgerPayment selected={payrollExpenseLedgerId} onChange={(e) => setpayrollExpenseLedgerId(e)} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item
                                name="attendanceFineHeadId"
                                label="Attendance Fine Head"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select" },
                                ]}
                            >
                                <SelectPayRollDeduction selected={attendanceFineHeadId} onChange={(e) => setattendanceFineHeadId(e)} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item
                                name="bonusHeadId"
                                label="Bonus Addition Head"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select" },
                                ]}
                            >
                                <SelectPayRollAddition selected={bonusHeadId} onChange={(e) => setbonusHeadId(e)} />
                            </Form.Item>
                        </Col>


                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item
                                name="bonusOutOfHeadId"
                                label="Addition Head For Bonus Calculation"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select" },
                                ]}
                            >
                                <SelectPayRollAddition2 selected={bonusOutOfHeadId} onChange={(e) => setbonusOutOfHeadId(e)} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Form.Item
                                name="bonusPercentageOfBasic"
                                label="Bonus Percentage of Bonus Calculation Head"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input" },
                                ]}
                            >
                                <Input placeholder="Bonus Percentage" />
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 22 }} xl={{ span: 22 }}> </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 2 }} xl={{ span: 2 }}>
                            <Button type="primary" htmlType="submit" icon={<SaveOutlined />} style={{ float: 'right', marginTop: -15 }} >
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </>
    )
}