import { DeleteOutlined, EditOutlined, SaveOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Form, Input, Modal, notification, Popconfirm, Row, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../../store/hooks/easyPeasy';
import Table from "../../../../../contents/AntTableResponsive";
import { SelectPayRollGrade } from '../../../../select/SelectPayRollGrade';


export default function EmployeeSalaryGrade(props) {

    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();
    const [updateForm2] = Form.useForm();

    const saveEmployeeSalaryConfigurationList = useStoreState((state) => state.payroll.saveEmployeeSalaryConfigurationList);
    const fetchsaveEmployeeSalaryConfigurationList = useStoreActions((state) => state.payroll.fetchsaveEmployeeSalaryConfigurationList);
    const saveEmployeeSalaryConfigurationByGrade = useStoreActions((state) => state.payroll.saveEmployeeSalaryConfigurationByGrade);



    useEffect(() => {
        fetchsaveEmployeeSalaryConfigurationList();
    }, [])


    const updateFomrSubmit = (value) => {
        let payload = {
            "gradeId": value?.gradeId,
            "staffIds": [
                staffId
            ]
          }
        saveEmployeeSalaryConfigurationByGrade(payload);
        setIsModalVisible(false);
        updateForm.resetFields()
    }

    const submitBatch = () => {
        if (selectedRowKeys.length === 0) {
            notification.error({ message: "Please select staff" });
            return
        };
        setIsModalVisible2(true);
    }
    const updateFomrSubmit2 = (value) => {
        let stafIds = selectedValue.map(item => item.staffId)

        let payload = {
            "gradeId": value?.gradeId,
            "staffIds": stafIds
          }
        saveEmployeeSalaryConfigurationByGrade(payload);
        setIsModalVisible2(false);
        updateForm2.resetFields();
        setselectedRowKeys([]);
        setselectedValue([])
    }

    const [staffId, setstaffid] = useState<any>();

    const columns = [

        {
            title: 'Staff Id',
            dataIndex: 'customStaffId',
            key: 'customStaffId',
            showOnResponse: true,
            showOnDesktop: true,
        },        
        {
            title: 'Staff Name',
            dataIndex: 'staffName',
            key: 'staffName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Designation',
            dataIndex: 'designation',
            key: 'designation',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Grade',
            dataIndex: 'grade',
            key: 'grade',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Gross Salary',
            dataIndex: 'grossSalary',
            key: 'grossSalary',
            showOnResponse: true,
            showOnDesktop: true,
        },        
        {
            title: 'Deduction Amount',
            dataIndex: 'deductionAmount',
            key: 'deductionAmount',
            showOnResponse: true,
            showOnDesktop: true,
        },        
        {
            title: 'Net Salary',
            dataIndex: 'netSalary',
            key: 'netSalary',
            showOnResponse: true,
            showOnDesktop: true,
        },        
        {
            title: 'Addition',
            dataIndex: 'additions',
            key: 'additions',
            showOnResponse: true,
            showOnDesktop: true,
        },        
        {
            title: 'Deductions',
            dataIndex: 'deductions',
            key: 'deductions',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Configure',
            key: 'studentId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Process">
                        <Button type='primary' icon={<SettingOutlined />} onClick={() => {
                            setIsModalVisible(true);
                            setstaffid(record.staffId);

                        }}
                        />
                    </Tooltip>


                </Space>
            ),
        },
    ];

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisible2, setIsModalVisible2] = useState(false);

    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
        // console.log(value)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <>

            {saveEmployeeSalaryConfigurationList?.length > 0 &&
                <div className="datatable-responsive">
                    {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} className="p-datatable-responsive-demo" /> */}

                    <Table
                        antTableProps={{
                            showHeader: true,
                            columns,
                            dataSource: saveEmployeeSalaryConfigurationList,
                            pagination: false,
                            bordered: true,
                            rowKey: "staffId",
                            rowSelection: rowSelection,
                        }}
                        mobileBreakPoint={768}
                    />
                    <Space size="middle" style={{ float: "right", marginTop: 15 }}>
                        <Button type="primary" onClick={submitBatch} icon={<SettingOutlined />} >Batch Process</Button>
                    </Space>
                </div>
            }


            <Modal
                title="Configure"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Save"
                centered
            >
                <Form
                    layout="vertical"

                    onFinish={updateFomrSubmit}
                    id="update"
                    form={updateForm}
                >
                    <Form.Item
                        name="gradeId"
                        label="Grade:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please select grade" },
                        ]}
                    >
                        <SelectPayRollGrade/>
                    </Form.Item>



                </Form>

            </Modal>
            <Modal
                title="Configure"
                visible={isModalVisible2}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible2(false)}
                cancelText="Close"
                okText="Save"
                centered
                destroyOnClose
            >
                <Form
                    layout="vertical"

                    onFinish={updateFomrSubmit2}
                    id="update"
                    form={updateForm2}
                >

                    <Form.Item
                        name="gradeId"
                        label="Grade:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please select grade" },
                        ]}
                    >
                        <SelectPayRollGrade/>
                    </Form.Item>
                </Form>

            </Modal>
        </>
    )
}
