import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import Column from 'rc-table/lib/sugar/Column';
import { EditOutlined, FileExcelOutlined, SearchOutlined, SwitcherOutlined } from '@ant-design/icons';
import TableView from '../../../contents/AntTableResponsive';
import { Excel } from 'antd-table-saveas-excel';

export default function InstiuteListCore() {
    // const fetchInstiuteListcore = useStoreActions((state) => state.customerSupport.fetchInstiuteListcore);
    // const instiuteListcore = useStoreState((state) => state.customerSupport.instiuteListcore);

    const { Option } = Select;

    const fetchInstiuteListFormanagement = useStoreActions((state) => state.customerSupport.fetchInstituteListForManagement);
    const instiuteListforManagement = useStoreState((state) => state.customerSupport.instiuteListForManagement);
    const loading = useStoreState((state) => state.customerSupport.loading);
    let year = new Date().getFullYear();

    // useEffect(() => {
    //     fetchInstiuteListcore();
    // }, []);


    const submitFormForInstituteList = (value) => {
        let postData: any = {
            "instituteStatus": value.instituteStatus === 3 ? null : value.instituteStatus,
            "instituteOwner": "SDL"
        }
        fetchInstiuteListFormanagement(postData);
    }


    const columns = [

        { title: 'Institute ID', dataIndex: 'instituteId', key: "instituteId", showOnResponse: true, showOnDesktop: true },
        { title: 'Institute Name', dataIndex: 'instituteName', key: "instituteName", showOnResponse: true, showOnDesktop: true },
        { title: 'Partner Name', dataIndex: 'partnerName', key: "partnerName", showOnResponse: true, showOnDesktop: true },
        { title: 'Type', dataIndex: 'instituteType', key: "instituteType", showOnResponse: true, showOnDesktop: true },
        { title: 'Bill Cycle', dataIndex: 'billCycle', key: "billCycle", showOnResponse: true, showOnDesktop: true },
        { title: 'Bill Type', dataIndex: 'billType', key: "billType", showOnResponse: true, showOnDesktop: true },
        { title: 'Bill Payable Person', dataIndex: 'billPayablePerson', key: "billPayablePerson", showOnResponse: true, showOnDesktop: true },
        { title: 'Package', dataIndex: 'packageName', key: "packageName", showOnResponse: true, showOnDesktop: true },
        { title: 'Bill Rate', dataIndex: 'billRate', key: "billRate", showOnResponse: true, showOnDesktop: true },
        { title: 'Total Student', dataIndex: 'totalStudent', key: "totalStudent", showOnResponse: true, showOnDesktop: true },
        { title: 'Minimum Student', dataIndex: 'minimumStudent', key: "minimumStudent", showOnResponse: true, showOnDesktop: true },
        { title: 'Institute Status', dataIndex: 'instituteStatus', key: "instituteStatus", showOnResponse: true, showOnDesktop: true },

    ];


    return (
        <>
            <Card title="Institute List">

                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 2 }} xl={{ span: 20, offset: 2 }}>
                        <Form
                            layout="vertical"
                            id="submitForm"
                            onFinish={submitFormForInstituteList}
                        >
                            <Row>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>

                                </Col>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="instituteStatus"
                                        label="Institute Staus:"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select Institute Staus" },
                                        ]}
                                        style={{ width: "100%" }}
                                    >
                                        <Select className='billStatusx' placeholder="Institute Status" allowClear style={{ width: "100%" }}>
                                            <Option value={0}>Inactive</Option>
                                            <Option value={1}>Active</Option>
                                            <Option value={2}>Pending</Option>
                                            <Option value={3}>All</Option>
                                        </Select>

                                    </Form.Item>
                                </Col>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 2 }} xl={{ span: 2 }}>
                                    <Space size="small" >
                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                {instiuteListforManagement?.length > 0 &&
                    <>
                        <Row className="m-t-mo-30">
                            <Col span={24}>
                                <div className="datatable-responsive-demo">
                                    <TableView
                                        antTableProps={{
                                            showHeader: true,
                                            columns,
                                            dataSource: instiuteListforManagement,
                                            filterData: instiuteListforManagement,
                                            pagination: true,
                                            bordered: true,
                                            rowKey: "instituteId",
                                        }}
                                        mobileBreakPoint={768}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Space size={'large'} style={{ float: "right" }}>
                            < Button
                                type='primary'
                                icon={<FileExcelOutlined />}
                                onClick={() => {
                                    const excel: any = new Excel();
                                    excel
                                        .addSheet(`Sheet1`)
                                        .addColumns(columns)
                                        .addDataSource(instiuteListforManagement)
                                        .saveAs(`Institute  List.xlsx`);
                                }}
                            >
                                Download Excel
                            </ Button >
                        </Space>
                    </>
                }
            </Card>

        </>
    )
}