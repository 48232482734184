import { DeleteOutlined, EditOutlined, SaveOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Table, Checkbox, Col, Form, Input, Modal, notification, Popconfirm, Row, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../../store/hooks/easyPeasy';
import TableResponsive from "../../../../../contents/AntTableResponsive";
import { SelectPayRollGrade } from '../../../../select/SelectPayRollGrade';


export default function EmployeeSalaryManual(props) {

    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();
    const [updateForm2] = Form.useForm();

    const saveEmployeeSalaryConfigurationList = useStoreState((state) => state.payroll.saveEmployeeSalaryConfigurationList);
    const fetchsaveEmployeeSalaryConfigurationList = useStoreActions((state) => state.payroll.fetchsaveEmployeeSalaryConfigurationList);
    const saveEmployeeSalaryConfigurationByManual = useStoreActions((state) => state.payroll.saveEmployeeSalaryConfigurationByManual);



    useEffect(() => {
        fetchsaveEmployeeSalaryConfigurationList();
    }, [])


    const updateFomrSubmit = () => {
        if (selectedRowKeys2.length == 0) {
            notification.error({ message: "Please select first" });
            return;
        };
        const additionList = selectedValue2?.map(item => {
            return {
                "additionHeadId": item?.additionId,
                "amount": item?.amount
            }
        });
        const deductionList = selectedValue3?.map(item => {
            return {
                "deductionHeadId": item?.deductionId,
                "amount": item?.amount
            }
        });

        let payload = {
            "additions": additionList,
            "deductions": deductionList,
            "staffIds": [staffId]
        };
        saveEmployeeSalaryConfigurationByManual(payload);
        setIsModalVisible(false);
        updateForm.resetFields();
        setselectedRowKeys2([]);
        setselectedRowKeys3([]);
        setselectedValue2([]);
        setselectedValue3([]);
    }

    const submitBatch = () => {
        if (selectedRowKeys.length === 0) {
            notification.error({ message: "Please select staff" });
            return
        };
        setIsModalVisible2(true);

        let addiList= selectedValue[0]?.additionList?.map(item=> {
            return {
                headName: item?.headName, 
                amount: 0,
                additionId: item?.additionId,  
            }
        })        
        let dedList= selectedValue[0]?.deductionList?.map(item=> {
            return {
                headName: item?.headName, 
                amount: 0, 
                deductionId: item?.deductionId, 
            }
        })

        setTableData([]);
        setTableData2([]);
        setTimeout(() => {
            setTableData(addiList);
            setTableData2(dedList);
        }, 100);
    }
    const updateFomrSubmit2 = () => {
        let stafIds = selectedValue.map(item => item.staffId)
        if (selectedRowKeys2.length == 0) {
            notification.error({ message: "Please select first" });
            return;
        };
        const additionList = selectedValue2?.map(item => {
            return {
                "additionHeadId": item?.additionId,
                "amount": item?.amount
            }
        });
        const deductionList = selectedValue3?.map(item => {
            return {
                "deductionHeadId": item?.deductionId,
                "amount": item?.amount
            }
        });

        let payload = {
            "additions": additionList,
            "deductions": deductionList,
            "staffIds": stafIds
        };
        saveEmployeeSalaryConfigurationByManual(payload);
        setIsModalVisible(false);
        updateForm.resetFields();
        setselectedRowKeys2([]);
        setselectedRowKeys3([]);
        setselectedValue2([]);
        setselectedValue3([]);
    }

    const [staffId, setstaffid] = useState<any>();

    const columns = [

        {
            title: 'Staff Id',
            dataIndex: 'customStaffId',
            key: 'customStaffId',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Staff Name',
            dataIndex: 'staffName',
            key: 'staffName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Designation',
            dataIndex: 'designation',
            key: 'designation',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Grade',
            dataIndex: 'grade',
            key: 'grade',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Gross Salary',
            dataIndex: 'grossSalary',
            key: 'grossSalary',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Deduction Amount',
            dataIndex: 'deductionAmount',
            key: 'deductionAmount',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Net Salary',
            dataIndex: 'netSalary',
            key: 'netSalary',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Addition',
            dataIndex: 'additions',
            key: 'additions',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Deductions',
            dataIndex: 'deductions',
            key: 'deductions',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Configure',
            key: 'studentId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Process">
                        <Button type='primary' icon={<SettingOutlined />} onClick={() => {
                            setIsModalVisible(true);
                            setstaffid(record.staffId);
                            setTableData([]);
                            setTableData2([]);
                            setTimeout(() => {
                                setTableData(record?.additionList);
                                setTableData2(record?.deductionList);
                            }, 100);
                        }}
                        />
                    </Tooltip>


                </Space>
            ),
        },
    ];



    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisible2, setIsModalVisible2] = useState(false);

    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);



    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
        // console.log(value)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const [tableData, setTableData] = useState<any>()
    const [tableData2, setTableData2] = useState<any>()

    const additioncols = [
        {
            title: 'Head Name',
            dataIndex: 'headName',
            key: 'headName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Input value={record?.amount} onChange={onchangeValue("amount", record, index)} style={{ width: "100%" }} />
            ),
        },
    ];

    const deductioncols = [
        {
            title: 'Head Name',
            dataIndex: 'headName',
            key: 'headName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Input value={record?.amount} onChange={onchangeValue2("amount", record, index)} style={{ width: "100%" }} />
            ),
        },
    ];

    const onchangeValue: any =
        (key, data, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const newData = [...tableData];
            newData[index][key] = e.target.value;
            setTableData(newData);
        };

    const onchangeValue2: any =
        (key, data, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const newData = [...tableData2];
            newData[index][key] = e.target.value;
            setTableData2(newData);
        };

    const [gross, setGross] = useState<any>(0);
    const [deduct, setdeduct] = useState<any>(0);
    const [net, setNet] = useState<any>(0);


    const [selectedRowKeys2, setselectedRowKeys2] = useState<any>([]);
    const [selectedValue2, setselectedValue2] = useState<any>([]);

    const onSelectChange2 = (selectedRowKeys2, value) => {
        setselectedRowKeys2(selectedRowKeys2);
        setselectedValue2(value);
        // console.log(value)
    };

    const rowSelection2 = {
        selectedRowKeys: selectedRowKeys2,
        onChange: onSelectChange2,
    };

    const [selectedRowKeys3, setselectedRowKeys3] = useState<any>([]);
    const [selectedValue3, setselectedValue3] = useState<any>([]);

    const onSelectChange3 = (selectedRowKeys3, value) => {
        setselectedRowKeys3(selectedRowKeys3);
        setselectedValue3(value);
        // console.log(value)
    };

    const rowSelection3 = {
        selectedRowKeys: selectedRowKeys3,
        onChange: onSelectChange3,
    };

    useEffect(() => {
        setGross(parseFloat(selectedValue2.reduce(function (acc, obj) { return acc + parseFloat(obj?.amount); }, 0)));
        setdeduct(parseFloat(selectedValue3.reduce(function (acc, obj) { return acc + parseFloat(obj?.amount); }, 0)));
        setNet(parseFloat(selectedValue2.reduce(function (acc, obj) { return acc + parseFloat(obj?.amount); }, 0))-parseFloat(selectedValue3.reduce(function (acc, obj) { return acc + parseFloat(obj?.amount); }, 0)));
    }, [selectedValue2, selectedValue3])

    return (
        <>

            {saveEmployeeSalaryConfigurationList?.length > 0 &&
                <div className="datatable-responsive">
                    {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} className="p-datatable-responsive-demo" /> */}

                    <TableResponsive
                        antTableProps={{
                            showHeader: true,
                            columns,
                            dataSource: saveEmployeeSalaryConfigurationList,
                            pagination: false,
                            bordered: true,
                            rowKey: "staffId",
                            rowSelection: rowSelection,
                        }}
                        mobileBreakPoint={768}
                    />
                    <Space size="middle" style={{ float: "right", marginTop: 15 }}>
                        <Button type="primary" onClick={submitBatch} icon={<SettingOutlined />} >Batch Process</Button>
                    </Space>
                </div>
            }


            <Modal
                title="Configure"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ style: { display: 'none' } }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Save"
                centered
                width={"80%"}
            >

                <Row>
                    <Col span={12}>
                        <Table
                            columns={additioncols}
                            dataSource={tableData}
                            bordered
                            pagination={false}
                            title={() => 'Additions'}
                            rowKey={"additionId"}
                            rowSelection={rowSelection2}
                        />

                    </Col>
                    <Col span={12}>
                        <Table
                            columns={deductioncols}
                            dataSource={tableData2}
                            bordered
                            pagination={false}
                            title={() => 'Dedcutions'}
                            rowKey={"deductionId"}
                            rowSelection={rowSelection3}
                        />

                    </Col>
                </Row>
                <br />
                <Row gutter={8}>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <label  className="ant-form-item-required" title="Gross Salary">Gross Salary</label>
                        <Input disabled value={gross}/>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <label  className="ant-form-item-required" title="Decution Amount">Decution Amount</label>
                        <Input disabled value={deduct}/>
                    </Col>                    
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <label  className="ant-form-item-required" title="Net Salary">Net Salary</label>
                        <Input disabled value={net}/>
                    </Col>
                </Row>
                <br />
                <Button type='primary' style={{ float: 'right' }} icon={<SaveOutlined />} onClick={() => updateFomrSubmit()}>Save</Button> <br />
            </Modal>
            <Modal
                title="Configure"
                visible={isModalVisible2}
                //  onOk={handleOk}
                okButtonProps={{ style: { display: 'none' } }}
                onCancel={() => setIsModalVisible2(false)}
                cancelText="Close"
                okText="Save"
                centered
                destroyOnClose
                width={"80%"}
            >
                <Row>
                    <Col span={12}>
                        <Table
                            columns={additioncols}
                            dataSource={tableData}
                            bordered
                            pagination={false}
                            title={() => 'Additions'}
                            rowKey={"additionId"}
                            rowSelection={rowSelection2}
                        />

                    </Col>
                    <Col span={12}>
                        <Table
                            columns={deductioncols}
                            dataSource={tableData2}
                            bordered
                            pagination={false}
                            title={() => 'Dedcutions'}
                            rowKey={"deductionId"}
                            rowSelection={rowSelection3}
                        />

                    </Col>
                </Row>
                <br />
                <Row gutter={8}>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <label  className="ant-form-item-required" title="Gross Salary">Gross Salary</label>
                        <Input disabled value={gross}/>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <label  className="ant-form-item-required" title="Decution Amount">Decution Amount</label>
                        <Input disabled value={deduct}/>
                    </Col>                    
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <label  className="ant-form-item-required" title="Net Salary">Net Salary</label>
                        <Input disabled value={net}/>
                    </Col>
                </Row>
                <br />
                <Button type='primary' style={{ float: 'right' }} icon={<SaveOutlined />} onClick={() => updateFomrSubmit2()}>Save</Button> <br />

            </Modal>
        </>
    )
}
