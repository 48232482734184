import { FileExcelOutlined, FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Form, Row, Skeleton, Space } from 'antd';
import React, { useState } from 'react';
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import moment from 'moment';
import { Column } from '@ant-design/plots';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf, lpowerdbypdf, ldatepdf, pdfGenerate } from '../../utils/pdf';
import TableView from '../../../contents/AntTableResponsive';
require('jspdf-autotable');

export default function PersonWiseBillCollection() {

    const fetchpartnerWiseSpgTransaction = useStoreActions((state) => state.customerSupport.fetchpartnerWiseSpgTransaction);
    const partnerWiseSpgTransaction = useStoreState((state) => state.customerSupport.partnerWiseSpgTransaction);
    const loading = useStoreState((state) => state.customerSupport.loading);
    const [pdata, setpdata] = useState<any>({ fromDate: '', toDate: '' })
    const callApiFunction = (value) => {
        let postData = {
            fromDate: moment(value?.fromDate).format("YYYY-MM-DD"),
            toDate: moment(value?.toDate).format("YYYY-MM-DD"),
        };
        setpdata(postData);
        fetchpartnerWiseSpgTransaction(postData);
    };

    // Restructure data for dual y-fields (feesPayment and serviceCharge)
    const chartData = partnerWiseSpgTransaction.flatMap(item => [
        {
            partnerName: item.partnerName,
            type: 'Fees Payment',
            value: item.feesPayment,
        },
        {
            partnerName: item.partnerName,
            type: 'Service Charge',
            value: item.serviceCharge,
        }
    ]);

    const config: any = {
        data: chartData,
        isGroup: true, // Ensures bars are grouped by partnerName
        xField: 'partnerName',
        yField: 'value', // The y-axis represents the 'value' field from chartData
        seriesField: 'type', // This distinguishes between 'Fees Payment' and 'Service Charge'
        label: {
            position: 'middle',
            layout: [
                { type: 'interval-adjust-position' },
                { type: 'interval-hide-overlap' },
                { type: 'adjust-color' },
            ],
        },
        scrollbar: {
            type: 'horizontal', // Enable horizontal scroll
        },
        slider: {
            start: 0,
            end: 1, // Adjust this range to control initial view
        },
    };

    const columns = [
        { title: 'Name', dataIndex: 'partnerName', key: "partnerName", showOnResponse: true, showOnDesktop: true },
        { title: 'Fees Payment', dataIndex: 'feesPayment', key: "feesPayment", showOnResponse: true, showOnDesktop: true },
        { title: 'Service Charge', dataIndex: 'serviceCharge', key: "serviceCharge", showOnResponse: true, showOnDesktop: true },
    ]

    function exportPdf() {

        var doc = new jsPDF("p", "mm", "a4");

        // var details = `Exam Fourth Subject Assigned List of ${$(".sectionSelect").text()}`;
        var details = `Partner wise bill collection from ${pdata?.fromDate} to ${pdata?.toDate} `;
        pdfDataL(doc, "");
        doc.text(details, 105, 40, 'center');
        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = ppowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + pdatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };


        var col = [
            "Name",
            "Fees Payment",
            "Service Charge"
        ].filter(Boolean);

        var rows: any = [];


        partnerWiseSpgTransaction?.forEach(element => {
            var temp: any = [
                element.partnerName,
                element.feesPayment,
                element.serviceCharge
            ];
            rows.push(temp);
        });

        doc.autoTable(col, rows, {
            startY: 45,
            showHeader: "firstPage",
            theme: 'grid',

            headerStyles: {
                // fillColor: [105, 105, 105],
                // textColor: [255, 255, 255],
                // fontSize: 10
                lineWidth: .01,
                lineColor: [224, 224, 224]
            },
            styles: {
                overflow: 'linebreak',
            },

            addPageContent: pageContent,

        });


        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        doc.setPage(1 + doc.internal.getCurrentPageInfo().pageNumber - doc.autoTable.previous.pageCount);
        doc.save(details + ".pdf");

    }

    return (
        <>
            <Card title="Person Wise Bill Collection">
                <Form
                    layout="vertical"
                    onFinish={callApiFunction}
                    id="searchReport"
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="fromDate"
                                label="From Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter from date" },
                                ]}
                            >
                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="toDate"
                                label="To Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter to date" },
                                ]}
                            >
                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small">
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                    Search
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>
                    </Row>
                </Form>
                <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                {partnerWiseSpgTransaction?.length > 0 &&
                    <>
                        {/* Scrollable X-axis Chart */}
                        <div style={{ overflowX: 'auto', paddingRight: 10 }}>
                            <Column {...config} /> <br />
                            <TableView
                                antTableProps={{
                                    showHeader: true,
                                    columns,
                                    dataSource: partnerWiseSpgTransaction,
                                    filterData: partnerWiseSpgTransaction,
                                    pagination: true,
                                    bordered: true,
                                    rowKey: "partnerId",
                                }}
                                mobileBreakPoint={768}
                            />
                            <Space style={{ float: 'right' }} size="middle">

                                <Button type="primary" icon={<FilePdfOutlined />} onClick={exportPdf} > Download PDF </Button>
                            </Space>
                        </div>
                    </>
                }
            </Card>
        </>
    );
}
